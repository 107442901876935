import {
  FIELD_SOLUTION_ENGINEER,
  SYSTEM_ADMIN,
  FLEET_ANALYST,
  MAP_ANALYST,
  userRoleOptions as userRoles,
  permissionData,
} from 'shared/constants/users';

export const shouldRenderItem = ({
  item,
  userRole,
  permissions,
  daysUntilExpiration,
  maintenanceDisabled,
  manageAlertsDisabled,
}) => {
  if (!item) return false;

  if (item.link === '/maintenance' && maintenanceDisabled) return false;
  if (item.link === '/managealerts' && manageAlertsDisabled) return false;

  const getHasPermissions = item => {
    return item.requiredPermissions && item.requiredPermissions.some(p => permissions?.includes(p));
  };

  const getHasRequiredRole = item => {
    return item.requiredRoles && item.requiredRoles.includes(userRole);
  };

  const getPassesRoleAndPermissionsCheck = item => {
    if (item?.mustPassRoleAndPermissionsCheck) {
      return getHasPermissions(item) && getHasRequiredRole(item);
    } else {
      return true;
    }
  };

  // shows the Organizations master page if the current org
  const getShowScopedNonAdminOrg = item => {
    if (item?.showScopedNonAdminOrg) {
      return (
        userRole === userRoles[FIELD_SOLUTION_ENGINEER].value ||
        userRole === userRoles[SYSTEM_ADMIN].value
      );
    }
  };

  // if a fleet analyst or map analyst doesnt have editAlerts permissions, the org menu would be empty for them, so hide it
  const getHideIfEmpty = item => {
    if (item?.hideIfEmpty) {
      let roleInQuestion =
        userRole === userRoles[FLEET_ANALYST].value || userRole === userRoles[MAP_ANALYST].value;
      let hasPermissionInQuestion = permissions?.includes(permissionData.editAlerts);
      if (roleInQuestion && !hasPermissionInQuestion) {
        return true;
      } else {
        return false;
      }
    } else return false;
  };

  return (
    (getHasRequiredRole(item) ||
      (getHasPermissions(item) && !(item.hideIfSubscriptionExpired && daysUntilExpiration <= 0))) &&
    !getShowScopedNonAdminOrg(item) &&
    !getHideIfEmpty(item) &&
    getPassesRoleAndPermissionsCheck(item)
  );
};

export const selectNavigationItemKeyFromPathname = (navigationItems, pathname) => {
  for (const [key, item] of Object.entries(navigationItems)) {
    // if the item has a link, check if the pathname includes the link
    if (
      (item.link && pathname.includes(item.link)) ||
      item.relatedRoutes?.some(link => pathname.includes(link))
    ) {
      return key;
    }
    // if the item has a dropdown, check if the pathname includes any of the dropdown links
    else if (item.dropdown) {
      // call this function recursively to check the dropdown items
      const foundKey = selectNavigationItemKeyFromPathname(item.dropdown, pathname);
      if (foundKey) return foundKey;
    }
  }
  return null;
};

export const getMaintenanceDisabled = ({ filteredOrganization }) => {
  return (
    filteredOrganization?.maintenanceEnabled !== undefined &&
    filteredOrganization?.maintenanceEnabled !== true
  );
};

export const getManageAlertsDisabled = ({ filteredOrganization }) => {
  return (
    filteredOrganization?.manageAlertsEnabled !== undefined &&
    filteredOrganization?.manageAlertsEnabled !== true
  );
};
