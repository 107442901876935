import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import React from 'react';
import { BlueButton } from 'shared/styles/components/Button';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const AddAdditionalNotificationButton = () => {
  const {
    actions: { addAlertNotificationAction },
    state: {
      alert: { alertId },
    },
  } = useAlertFormsProviderContext();

  return (
    <BlueButton
      type="button"
      addNotification
      onClick={() => addAlertNotificationAction({ alertId })}
    >
      <IconSvgComponent svgFileName="plus-white" alt="Add Additional Notification" />
      Add Additional Notification
    </BlueButton>
  );
};

export default AddAdditionalNotificationButton;
