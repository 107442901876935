import { useEffect, useState } from 'react';

import { timeZoneOptions } from 'features/geofences/constants/dropdownOptions';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { FormAsterisk, FormLabelAnimated } from 'shared/styles/components/Form';
import {
  CheckboxDayLabelSpan,
  DayRangeOptionsDiv,
  DurationDiv,
  RangeDiv,
  SimpleRangeDiv,
  TimeDiv,
  TimeZoneDiv,
} from 'shared/styles/components/Geofences';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import SelectField from 'shared/ui/fields/SelectField';
import {
  convertGeofenceDuration,
  getDurationFromStartAndEndTimes,
  getTimeOptionsIn15MinuteIncrements,
} from 'shared/utilities/time';

let timeOptions = getTimeOptionsIn15MinuteIncrements();

const dropdownTimeOptions = timeOptions.map(t => ({ label: t, value: t })) || [];

const GeofenceRangeMapCard = ({
  setGeofenceCardData,
  rangeStartTime,
  rangeEndTime,
  duration,
  timeZone,
  activeRange,
}) => {
  const [durationDisplay, setDurationDisplay] = useState('N/A');

  const handleStartTimeSelect = label => {
    if (label) {
      setGeofenceCardData(pgf => {
        pgf.rangeStartTime = label.label;
        pgf.duration = getDurationFromStartAndEndTimes({
          startTime: pgf?.rangeStartTime,
          endTime: pgf.rangeEndTime,
        });
        pgf.activeRange.forEach(item => {
          item.start = pgf.rangeStartTime;
          item.duration = pgf.duration;
        });
        return { ...pgf };
      });
    } else {
      return null;
    }
  };

  const handleEndTimeSelect = label => {
    if (label) {
      setGeofenceCardData(pgf => {
        pgf.rangeEndTime = label.label;
        pgf.duration = getDurationFromStartAndEndTimes({
          startTime: pgf.rangeStartTime,
          endTime: pgf.rangeEndTime,
        });
        pgf.activeRange.forEach(item => {
          item.duration = pgf.duration;
        });
        return { ...pgf };
      });
    } else {
      return null;
    }
  };

  useEffect(() => {
    setDurationDisplay(convertGeofenceDuration(duration));
  }, [duration]);

  return (
    <>
      <RangeDiv>
        <TimeDiv>
          <FilteredDropdown
            title={'Start Time'}
            dropdownOptions={dropdownTimeOptions}
            handleItemSelect={label => {
              handleStartTimeSelect(label);
            }}
            styleOptions={{
              narrowWidth: true,
              hideClearX: true,
            }}
            defaultItem={null}
            placeholderLabel={rangeStartTime ? rangeStartTime : '---'}
          />
        </TimeDiv>
        <TimeDiv>
          <FilteredDropdown
            title={'End time'}
            dropdownOptions={dropdownTimeOptions}
            handleItemSelect={label => {
              handleEndTimeSelect(label);
            }}
            styleOptions={{
              narrowWidth: true,
              hideClearX: true,
            }}
            defaultItem={null}
            placeholderLabel={rangeEndTime ? rangeEndTime : '---'}
          />
        </TimeDiv>
        <DurationDiv>
          <FormLabelAnimated animated>Duration</FormLabelAnimated>
          <AnimatedField geofenceCard name="duration" disabled={true} value={durationDisplay} />
        </DurationDiv>
        <TimeZoneDiv>
          <FormLabelAnimated animated>
            Time Zone<FormAsterisk>*</FormAsterisk>
          </FormLabelAnimated>
          <SelectField
            geofenceCard
            mediumTextMobile
            name="rangeTimeZone"
            disabled={false}
            isClearable={false}
            isSearchable={false}
            options={timeZoneOptions}
            defaultValue={timeZone}
            onChange={({ value }) => {
              setGeofenceCardData(pgf => ({ ...pgf, timeZone: value }));
            }}
            field="true"
          />
        </TimeZoneDiv>
      </RangeDiv>

      <DayRangeOptionsDiv>
        <SimpleRangeDiv>
          {activeRange?.map((d, i) => (
            <div key={i}>
              <Checkbox singleCheck>
                <CheckboxInner
                  isChecked={activeRange[i].enabled}
                  onClick={() => {
                    let thisActiveRange = [...activeRange];
                    thisActiveRange[i].enabled = !thisActiveRange[i].enabled;

                    if (thisActiveRange[i].enabled === true) {
                      thisActiveRange[i].start = rangeStartTime;
                      thisActiveRange[i].duration = duration;
                    } else {
                      // need to pass valid string and number to the backend, even when we aren't using the range to match their validation schema
                      thisActiveRange[i].start = '12:00 am';
                      thisActiveRange[i].duration = 0;
                    }
                    setGeofenceCardData(pgf => ({
                      ...pgf,
                      activeRange: thisActiveRange,
                    }));
                  }}
                />
              </Checkbox>
              <CheckboxDayLabelSpan>{d.label}</CheckboxDayLabelSpan>
            </div>
          ))}
        </SimpleRangeDiv>
      </DayRangeOptionsDiv>
    </>
  );
};

export default GeofenceRangeMapCard;
