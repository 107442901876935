import dateFnsFormat from 'date-fns/format';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { generateAccessToken } from 'core/api/organizations';
import { modalFormChanged } from 'core/redux/ui/actions';
import colors from 'shared/constants/colors';
import { stateOptionsArray } from 'shared/constants/states';
import {
  FIELD_SOLUTION_ENGINEER,
  MAP_ANALYST,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'shared/constants/users';
import ConfirmationModal from 'shared/modals/ConfirmationModal';
import ModalPortal from 'shared/modals/ModalPortal';
import CheckboxInner from 'shared/styles/components//CheckboxInner';
import Button, { GenerateCodeButton } from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import FormWrapper, {
  CheckboxTextSpan,
  DateRangeValidationDiv,
  FieldError,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormLabelAnimated,
  FormLegend,
  FormSectionLabel,
  FormMessage,
  OrganizationDateDiv,
  HaasStatus,
  HassIntegrationWrapperDiv,
  OptInFeatureWrapper,
} from 'shared/styles/components/Form';
import { CloseButton, Container, Title } from 'shared/styles/components/Modal';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import MaskedField from 'shared/ui/fields/MaskedField';
import SelectField from 'shared/ui/fields/SelectField';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import StyledDayPickerInput from 'shared/ui/StyledDayPickerInput';
import { handleSelectBlur, handleSelectChange } from 'shared/utilities/form';
import { replaceEmptyString, replaceWithEmptyString } from 'shared/utilities/general';
import { validateOrganization } from 'shared/utilities/validators';
import { customerDropdownOptions, customerTypeData } from 'shared/constants/organizations';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { timeZoneOptions } from 'features/geofences/constants/dropdownOptions';

class OrganizationForm extends Component {
  static MODE = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
  };

  static FORM_TITLE = {
    CREATE: 'Create Organization',
    EDIT: 'Edit Organization',
  };

  static FORM_ICON = {
    CREATE: 'plus',
    EDIT: 'edit-pencil-blue',
  };

  state = {
    haasChecked: this.props.organization ? this.props.organization.haasEnabled : false,
    maintenanceChecked: this.props.organization
      ? this.props.organization.maintenanceEnabled
      : false,
    manageAlertsChecked: this.props.organization
      ? this.props.organization.manageAlertsEnabled
      : false,
    alertsActiveChecked: this.props.organization ? !this.props.organization.alertsActive : false,
    haasActivationMessage: !this.props.organization
      ? null
      : this.props.organization.haasEnabled
      ? this.props.organization.haasSetup
        ? 'WCP/HAAS Integration is currently enabled'
        : 'WCP/HAAS Integration is currently pending'
      : null,
    modalFormMessage: null,
    gttAccessKeyModalActive: false,
    gttAccessKey: null,
    regenerateGttAccessKeyModalActive: false,
  };

  setGlobalFormDirty = innerHandler => e => {
    this.props.modalFormChanged(e);
    innerHandler(e);
  };

  confirmHandler = async (values, formikActions) => {
    const { confirmHandler, handleRequestClose } = this.props;
    console.log('Muted?', this.state.alertsActiveChecked);
    const success = await confirmHandler({
      ...values,
      haasEnabled: this.state.haasChecked,
      maintenanceEnabled: this.state.maintenanceChecked,
      manageAlertsEnabled: this.state.manageAlertsChecked,
      //flip value because we are muting alerts
      alertsActive: !this.state.alertsActiveChecked,
      contactPhone: (values.contactPhone && `+${values.contactPhone.replace(/\D/g, '')}`) || '',
      organizationId: this.props.organization ? this.props.organization.organizationId : null,
      contactPhoneExtension: replaceWithEmptyString(values.contactPhoneExtension),
      vehicleLimit: +values.vehicleLimit,
    });
    formikActions.setSubmitting(false);
    if (success) {
      handleRequestClose();
    }
  };

  confirmKeyGeneration = () => {
    if (this.props.organization.gttSetup) {
      this.setState({
        regenerateGttAccessKeyModalActive: true,
        gttAccessKey: null,
      });
    } else {
      this.generateAccess();
    }
  };

  hasPermissionsForGtt = userRole =>
    userRole === userRoles[ORGANIZATION_ADMIN].value ||
    userRole === userRoles[FIELD_SOLUTION_ENGINEER].value;

  hasPermissionsForHaas = userRole =>
    userRole === userRoles[ORGANIZATION_ADMIN].value ||
    userRole === userRoles[FIELD_SOLUTION_ENGINEER].value;

  generateAccess = async () => {
    try {
      let { response } = await generateAccessToken({
        organizationId: this.props.organization.organizationId,
      });
      if (response.message) {
        this.setState({
          regenerateGttAccessKeyModalActive: false,
          gttAccessKeyModalActive: true,
          gttAccessKey: response.message.gtt_access_id,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getMuteAlertsOption = () => {
    return (
      <>
        <FormSectionLabel gtt>Organization Alerts</FormSectionLabel>
        <OptInFeatureWrapper>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={this.state.alertsActiveChecked}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              onClick={() => {
                this.setState({
                  alertsActiveChecked: !this.state.alertsActiveChecked,
                });
              }}
            />
          </Checkbox>

          <CheckboxTextSpan>Mute Alerts</CheckboxTextSpan>
        </OptInFeatureWrapper>
      </>
    );
  };

  render() {
    const {
      organization,
      mode,
      handleRequestClose,
      userRole,
      modalFormMessage,
      closeGlobalModal,
    } = this.props;
    const { gttAccessKeyModalActive, gttAccessKey, regenerateGttAccessKeyModalActive } = this.state;

    const isReadOnly =
      userRole !== userRoles[SYSTEM_ADMIN].value &&
      userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
        ? true
        : false;

    return (
      <Container autoHeight maxWidth={750}>
        {gttAccessKeyModalActive ? (
          <ModalPortal
            onRequestClose={() =>
              this.setState({
                gttAccessKeyModalActive: false,
              })
            }
          >
            <ConfirmationModal
              confirmHandler={() =>
                this.setState({
                  gttAccessKeyModalActive: false,
                })
              }
              bodyText={
                <>
                  <span>
                    This is your one time access key. Please make sure to copy it for use in the GTT
                    cloud
                  </span>
                  <div style={styles.accessKeyText}>{gttAccessKey}</div>
                </>
              }
              confirmText={'Got it'}
              title={'GTT Access Key'}
            />
          </ModalPortal>
        ) : null}
        {regenerateGttAccessKeyModalActive ? (
          <ModalPortal
            onRequestClose={() =>
              this.setState({
                regenerateGttAccessKeyModalActive: false,
              })
            }
          >
            <ConfirmationModal
              confirmHandler={() => {
                this.generateAccess();
              }}
              bodyText={
                <>
                  <span>
                    Do you really want to generate a new access key? This cannot be undone. You will
                    need to replace your old key in the GTT cloud with the new one that will be
                    generated.
                  </span>
                  <div style={styles.accessKeyText}>{gttAccessKey}</div>
                </>
              }
              cancelHandler={() =>
                this.setState({
                  regenerateGttAccessKeyModalActive: false,
                })
              }
              cancelText={'Cancel'}
              confirmText={'Confirm and Generate'}
              title={'Generate New Key'}
            />
          </ModalPortal>
        ) : null}
        <CloseButton onClick={handleRequestClose}>
          <IconSvgComponent svgFileName={'x'} />
        </CloseButton>

        <Title style={styles.title}>
          <IconSvgComponent style={styles.icon} svgFileName={OrganizationForm.FORM_ICON[mode]} />
          {OrganizationForm.FORM_TITLE[mode]}
        </Title>

        <FormWrapper>
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={
              (organization && {
                organizationName: organization.organizationName,
                contactName: organization.contactName,
                contactPhone: organization.contactPhone,
                contactPhoneExtension: replaceEmptyString(organization.contactPhoneExtension),
                contactEmail: organization.contactEmail,
                customerType: organization.customerType,
                address1: organization.address1,
                address2: organization.address2,
                city: organization.city,
                state: organization.state,
                vehicleLimit: organization.vehicleLimit,
                startDate: organization.startDate,
                endDate: organization.endDate,
                gttEnabled: organization.gttEnabled,
                gttSetup: organization.gttSetup,
                haasEnabled: organization.haasEnabled,
                maintenanceEnabled: organization.maintenanceEnabled,
                manageAlertsEnabled: organization.manageAlertsEnabled,
                haasSetup: organization.haasSetup,
                wimAccountId: organization.wimAccountId,
                timeZone: organization.timeZone,
              }) || {
                organizationName: '',
                contactName: '',
                contactPhone: '1',
                contactPhoneExtension: '',
                contactEmail: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                vehicleLimit: '',
                startDate: dateFnsFormat(new Date(), 'MM/dd/yyyy'),
                endDate: dateFnsFormat(new Date(), 'MM/dd/yyyy'),
                gttEnabled: false,
                gttSetup: false,
                haasEnabled: false,
                maintenanceEnabled: false,
                manageAlertsEnabled: false,
                haasSetup: false,
                wimAccountId: '',
              }
            }
            validate={validateOrganization}
            onSubmit={this.confirmHandler}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              handleChange: formikHandleChange,
              handleBlur,
              submitForm,
              setFieldValue,
              setTouched,
              isSubmitting,
            }) => {
              const handleChange = dirty
                ? formikHandleChange
                : this.setGlobalFormDirty(formikHandleChange);
              return (
                <Form onSubmit={submitForm}>
                  <FormBody>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="organizationName"
                          placeholder="Organization Name"
                          value={values.organizationName}
                          touched={touched.organizationName}
                          validationError={errors.organizationName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={
                            userRole !== userRoles[SYSTEM_ADMIN].value &&
                            userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
                          }
                          required
                        />
                      </FormCol>
                      <FormCol>
                        <AnimatedField
                          name="contactName"
                          placeholder="Contact Name"
                          value={values.contactName}
                          touched={touched.contactName}
                          validationError={errors.contactName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <div>
                          <MaskedField
                            name="contactPhone"
                            placeholder="Contact Phone"
                            value={values.contactPhone}
                            touched={touched.contactPhone}
                            validationError={errors.contactPhone}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            disabled={false}
                            required
                            mask="+9 (999) 999-9999"
                            containerStyles={styles.phoneNumber}
                          />
                          <AnimatedField
                            name="contactPhoneExtension"
                            placeholder="Extension"
                            value={values.contactPhoneExtension}
                            touched={touched.contactPhoneExtension}
                            validationError={errors.contactPhoneExtension}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            containerStyles={styles.phoneNumber}
                          />
                        </div>
                      </FormCol>

                      <FormCol>
                        <AnimatedField
                          name="contactEmail"
                          placeholder="Contact Email"
                          value={values.contactEmail}
                          touched={touched.contactEmail}
                          validationError={errors.contactEmail}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <OrganizationDateDiv>
                          <FormLabelAnimated animated>
                            Start Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                          </FormLabelAnimated>
                          {isReadOnly ? (
                            <div>{values.startDate}</div>
                          ) : (
                            <>
                              <StyledDayPickerInput
                                name="startDate"
                                validateOnChange={false}
                                placeholder={values.startDate}
                                onDayChange={day => {
                                  setFieldValue('startDate', `${dateFnsFormat(day, 'MM/dd/yyyy')}`);
                                }}
                              />
                              <DateRangeValidationDiv>{errors.startDate}</DateRangeValidationDiv>
                            </>
                          )}
                        </OrganizationDateDiv>
                      </FormCol>

                      <FormCol>
                        <OrganizationDateDiv>
                          <FormLabelAnimated animated>
                            End Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                          </FormLabelAnimated>
                          {isReadOnly ? (
                            <div>{values.endDate}</div>
                          ) : (
                            <>
                              <StyledDayPickerInput
                                name="endDate"
                                placeholder={values.endDate}
                                onDayChange={day => {
                                  setFieldValue('endDate', `${dateFnsFormat(day, 'MM/dd/yyyy')}`);
                                }}
                              />
                              <DateRangeValidationDiv>{errors.endDate}</DateRangeValidationDiv>
                            </>
                          )}
                        </OrganizationDateDiv>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="address1"
                          placeholder="Address 1"
                          value={values.address1}
                          touched={touched.address1}
                          validationError={errors.address1}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>

                      <FormCol>
                        <AnimatedField
                          name="address2"
                          placeholder="Address 2"
                          value={values.address2}
                          touched={touched.address2}
                          validationError={errors.address2}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="city"
                          placeholder="City"
                          value={values.city}
                          touched={touched.city}
                          validationError={errors.city}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>

                      <FormCol>
                        <SelectField
                          name="licenseState"
                          placeholder={'State'}
                          defaultValue={values.state}
                          disabled={false}
                          onChange={handleSelectChange(setFieldValue, 'state')}
                          onBlur={handleSelectBlur(setTouched, 'state', touched)}
                          isClearable={false}
                          isSearchable={false}
                          options={stateOptionsArray}
                          field="true"
                          required
                        />
                        <FieldError>{touched.state && errors.state}</FieldError>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="vehicleLimit"
                          placeholder="Vehicle Limit"
                          value={values.vehicleLimit}
                          touched={touched.vehicleLimit}
                          validationError={errors.vehicleLimit}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={
                            userRole !== userRoles[SYSTEM_ADMIN].value &&
                            userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
                          }
                          required
                        />
                      </FormCol>

                      <FormCol>
                        <AnimatedField
                          name="wimAccountId"
                          placeholder="WIM Account ID"
                          value={values.wimAccountId}
                          touched={touched.wimAccountId}
                          validationError={errors.wimAccountId}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={
                            userRole !== userRoles[SYSTEM_ADMIN].value &&
                            userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
                          }
                          required
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <FormLabelAnimated animated>
                          Customer Type<FormAsterisk>*</FormAsterisk>
                        </FormLabelAnimated>
                        <StyledReactSelect
                          name="customerType"
                          placeholder={'Customer Type'}
                          defaultValue={{
                            label: values.customerType
                              ? customerDropdownOptions.find(o => o.value === values.customerType)
                                  .label
                              : 'Select a type',
                          }}
                          disabled={false}
                          onChange={handleSelectChange(setFieldValue, 'customerType')}
                          onBlur={handleSelectBlur(setTouched, 'customerType', touched)}
                          isClearable={false}
                          isSearchable={false}
                          options={customerDropdownOptions.filter(
                            o => o.value !== customerTypeData.EMERGENCY_RESPONSE,
                          )}
                          field="true"
                          required
                        />
                        <FieldError topSpacing>{errors.customerType}</FieldError>
                      </FormCol>
                      <FormCol>
                        <FormLabelAnimated animated>
                          Time Zone<FormAsterisk>*</FormAsterisk>
                        </FormLabelAnimated>
                        <StyledReactSelect
                          mediumTextMobile
                          name="timeZone"
                          disabled={false}
                          isClearable={false}
                          isSearchable={false}
                          options={timeZoneOptions}
                          placeholder={'Time Zone'}
                          defaultValue={{
                            label: values.timeZone
                              ? timeZoneOptions.find(t => t.value === values.timeZone)?.label
                              : 'Select a time zone',
                          }}
                          onChange={handleSelectChange(setFieldValue, 'timeZone')}
                          onBlur={handleSelectBlur(setTouched, 'timeZone', touched)}
                          field="true"
                        />
                        <FieldError topSpacing>{errors.timeZone}</FieldError>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      {/* TEMP ROLE CHECK UNTIL PHASE 2 OF GTT */}
                      <FormCol>
                        <div>
                          <FormSectionLabel gtt>GTT Integration</FormSectionLabel>
                          <Checkbox singleCheck>
                            <CheckboxInner
                              isChecked={values.gttEnabled}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              disabledStyleProp={!this.hasPermissionsForGtt(userRole)}
                              onClick={() => setFieldValue('gttEnabled', !values.gttEnabled)}
                            />
                          </Checkbox>
                          <CheckboxTextSpan>Enable GTT Integration</CheckboxTextSpan>
                          {this.hasPermissionsForGtt(userRole) && (
                            <div>
                              {this.props.organization && values.gttEnabled && (
                                <GenerateCodeButton
                                  type="button"
                                  onClick={this.confirmKeyGeneration}
                                >
                                  Generate Access Key
                                </GenerateCodeButton>
                              )}
                            </div>
                          )}
                        </div>
                      </FormCol>
                      <FormCol>
                        <>
                          <HassIntegrationWrapperDiv>
                            <FormSectionLabel gtt>HAAS Integration</FormSectionLabel>
                            <Checkbox singleCheck>
                              <CheckboxInner
                                isChecked={this.state.haasChecked}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabledStyleProp={!this.hasPermissionsForHaas(userRole)}
                                onClick={() => {
                                  this.setState({
                                    haasChecked: !this.state.haasChecked,
                                  });
                                }}
                              />
                            </Checkbox>
                            <CheckboxTextSpan>Enable HAAS Integration</CheckboxTextSpan>
                            <FormLegend>
                              <HaasStatus>{this.state.haasActivationMessage}</HaasStatus>
                            </FormLegend>
                          </HassIntegrationWrapperDiv>
                        </>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      {/* TEMP FEATURE ENABLE/DISABLE UNTIL FULL MAINTENANCE ROLLOUT */}

                      <FormCol>
                        {userRole === userRoles[FIELD_SOLUTION_ENGINEER].value ||
                        userRole === userRoles[SYSTEM_ADMIN].value ? (
                          <>
                            <FormSectionLabel gtt>Opt In Features</FormSectionLabel>
                            <OptInFeatureWrapper>
                              <Checkbox singleCheck>
                                <CheckboxInner
                                  isChecked={this.state.maintenanceChecked}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  onClick={() => {
                                    this.setState({
                                      maintenanceChecked: !this.state.maintenanceChecked,
                                    });
                                  }}
                                />
                              </Checkbox>

                              <CheckboxTextSpan>Enable Maintenance Feature</CheckboxTextSpan>
                            </OptInFeatureWrapper>
                            <OptInFeatureWrapper>
                              <Checkbox singleCheck>
                                <CheckboxInner
                                  isChecked={this.state.manageAlertsChecked}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  onClick={() => {
                                    this.setState({
                                      manageAlertsChecked: !this.state.manageAlertsChecked,
                                    });
                                  }}
                                />
                              </Checkbox>
                              <CheckboxTextSpan>Enable Alerts Feature</CheckboxTextSpan>
                            </OptInFeatureWrapper>
                          </>
                        ) : (
                          this.getMuteAlertsOption()
                        )}
                      </FormCol>
                      {userRole === userRoles[FIELD_SOLUTION_ENGINEER].value ||
                      userRole === userRoles[SYSTEM_ADMIN].value ? (
                        <FormCol>{this.getMuteAlertsOption()}</FormCol>
                      ) : null}
                    </FormGridRow>
                    <FormMessage message={modalFormMessage}>{modalFormMessage}</FormMessage>
                  </FormBody>

                  <FormFoot>
                    <FormLegend>
                      <FormAsterisk>*</FormAsterisk> &mdash; required fields
                    </FormLegend>

                    <FormActions>
                      <Button
                        onClick={handleRequestClose}
                        withRightSpacer
                        mediumAlt
                        default
                        type="button"
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoader
                        isLoading={isSubmitting}
                        confirmText={OrganizationForm.FORM_TITLE[mode]}
                        loadingStyleProp={'submittingWithSpinnerModal'}
                        notLoadingStyleProp={'mediumAlt'}
                        clickHandler={submitForm}
                      />
                    </FormActions>
                  </FormFoot>
                </Form>
              );
            }}
          </Formik>
        </FormWrapper>
      </Container>
    );
  }
}

export default connect(
  state => ({
    modalFormMessage: state.ui.modalFormMessage,
  }),
  { modalFormChanged },
)(OrganizationForm);

const styles = {
  title: {
    marginBottom: '42px',
  },
  icon: {
    marginRight: '10px',
  },
  acountForm: {
    minWidth: '700',
  },
  phoneNumber: {
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    marginBottom: '10px',
  },
  accessKeyText: {
    color: colors.midnight,
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 30,
  },
};
