import { maintenanceStatusDropdownData } from 'app/features/maintenance/data/maintenanceStatusDropdownData';
import { maintenanceTypeDropdownData } from 'app/features/maintenance/data/maintenanceTypeDropdownData';
import { MaintenanceFilters } from 'shared/styles/components/Maintenance';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';

const MaintenanceSummaryFilters = ({
  vehicles,
  groups,
  selectedVehicle,
  selectedStatus,
  selectedGroup,
  selectedType,
  setMaintenanceSummaryFilters,
}) => {
  const defaultSelectedVehicle = vehicles?.find(v => v.vehicle_id === selectedVehicle?.vehicle_id)
    ?.meta.label;
  const defaultSelectedStatus = maintenanceStatusDropdownData?.find(d => d === selectedStatus)
    ?.label;

  const defaultSelectedGroup = groups?.find(g => g.group_id === selectedGroup?.group_id)
    ?.group_name;
  const defaultSelectedType = maintenanceTypeDropdownData?.find(t => t === selectedType)?.label;
  const groupOptions = groups?.map(group => ({ label: group.group_name, value: group.group_name }));
  const vehicleOptions = vehicles?.map(vehicle => ({
    label: vehicle.meta.label,
    value: vehicle.meta.label,
  }));

  return (
    <MaintenanceFilters>
      <FilteredDropdown
        title={'Groups'}
        placeholderLabel={defaultSelectedGroup || 'All Groups'}
        dropdownOptions={groupOptions}
        handleItemSelect={selectedItem => {
          if (selectedItem) {
            setMaintenanceSummaryFilters({
              selectedGroup: groups.find(g => g.group_name === selectedItem.label),
            });
          } else {
            setMaintenanceSummaryFilters({ selectedGroup: null });
          }
        }}
        defaultItem={null}
        styleOptions={{}}
      />
      <FilteredDropdown
        title={'Type'}
        placeholderLabel={defaultSelectedType || 'All Types'}
        dropdownOptions={maintenanceTypeDropdownData}
        handleItemSelect={selectedItem => {
          if (selectedItem) {
            setMaintenanceSummaryFilters({
              selectedType: maintenanceTypeDropdownData.find(t => t.label === selectedItem.label),
            });
          } else {
            setMaintenanceSummaryFilters({ selectedType: null });
          }
        }}
        defaultItem={null}
        styleOptions={{}}
      />
      <FilteredDropdown
        title={'Status'}
        placeholderLabel={defaultSelectedStatus || 'All Statuses'}
        dropdownOptions={maintenanceStatusDropdownData}
        handleItemSelect={selectedItem => {
          if (selectedItem) {
            setMaintenanceSummaryFilters({
              selectedStatus: maintenanceStatusDropdownData.find(
                t => t.label === selectedItem.label,
              ),
            });
          } else {
            setMaintenanceSummaryFilters({ selectedStatus: null });
          }
        }}
        styleOptions={{}}
        defaultItem={null}
      />
      <FilteredDropdown
        title={'Vehicle'}
        placeholderLabel={defaultSelectedVehicle || 'All Vehicles'}
        dropdownOptions={vehicleOptions}
        handleItemSelect={selectedItem => {
          if (selectedItem) {
            setMaintenanceSummaryFilters({
              selectedVehicle: vehicles.find(v => v.meta.label === selectedItem.label),
            });
          } else {
            setMaintenanceSummaryFilters({ selectedVehicle: null });
          }
        }}
        defaultItem={
          defaultSelectedVehicle
            ? { label: defaultSelectedVehicle, value: defaultSelectedVehicle }
            : null
        }
        styleOptions={{}}
      />
    </MaintenanceFilters>
  );
};

export default MaintenanceSummaryFilters;
