import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import AdditionalNotificationContainer from 'app/features/alerts/ui/components/AlertNotificationBuilder/containers/AdditionalNotificationContainer';
import PrimaryNotificationContainer from 'app/features/alerts/ui/components/AlertNotificationBuilder/containers/PrimaryNotificationContainer';
import { RecipientBuilderWrapper } from 'shared/styles/components/ManageAlerts';

const AlertNotificationBuilder = () => {
  const {
    state: {
      alert: { alertNotifications },
    },
  } = useAlertFormsProviderContext();

  // if additional alerts have no recipients, throw an error
  if (
    alertNotifications?.length > 1 &&
    alertNotifications?.slice(1).every(notification => notification.alertRecipients?.length === 0)
  ) {
    throw new Error('Additional alerts must have at least one recipient');
  }

  let primaryNotification = alertNotifications[0];
  let additionalNotifications = alertNotifications.slice(1) || [];

  return (
    <RecipientBuilderWrapper>
      <PrimaryNotificationContainer
        hasRecipients={primaryNotification.alertRecipientRows.length > 0}
      />
      {additionalNotifications.length > 0 &&
        additionalNotifications.map((_, i) => (
          <AdditionalNotificationContainer key={i} notificationIndex={i + 1} />
        ))}
    </RecipientBuilderWrapper>
  );
};

export default AlertNotificationBuilder;
