import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';
import React from 'react';
import { RecipientWrapper } from 'shared/styles/components/RecipientGroups';

const mapUserToDropdownOption = user =>
  user
    ? {
        label: `${user.firstName} ${user.lastName}`,
        value: user.userName,
      }
    : null;

const RecipientSelectDropdown = ({ index, selectedUser, availableUsers, handleUserSelect }) => {
  const dropdownOptions = availableUsers.map(mapUserToDropdownOption);

  const defaultItem = mapUserToDropdownOption(selectedUser);

  return (
    <RecipientWrapper>
      <FilteredDropdown
        styleOptions={{
          hideClearX: true,
          required: true,
        }}
        title="Recipient"
        placeholderLabel="Choose a recipient"
        defaultItem={defaultItem}
        dropdownOptions={dropdownOptions}
        handleItemSelect={selectedItem => {
          const selectedUser = availableUsers?.find(u => u.userName === selectedItem?.value);
          handleUserSelect(selectedUser);
        }}
      />
    </RecipientWrapper>
  );
};

export default RecipientSelectDropdown;
