import { BackendValidationError } from 'app/core/error/classes/BackendValidationError';
// function to convert backend property name with underscores to camelCase
const convertSnakeCaseToCamelCase = str => {
  return str.replace(/_([a-z])/g, g => g[1].toUpperCase());
};

export const mapToFrontend = (sourceData, mapping, discardUnmappedProperties = true) => {
  // assert that data and mapping are not null
  if (!sourceData) throw new Error(`Mapper Data Error: ${sourceData}`);
  if (!mapping) throw new Error(`Mapper Mapping Error: ${mapping}`);

  let mappedAndTransformed = {};

  Object.keys(sourceData).forEach(key => {
    // get the mappingInfo for the key
    const mappingInfo = mapping[key];

    // if mappingInfo is defined...
    if (mappingInfo) {
      // if required is true and the key is not present in the data, throw a BackendValidationError
      if (mappingInfo.required && !sourceData[key]) {
        throw new BackendValidationError(`Mapper Required Error: ${key}`);
      }

      // Use the transform function if defined, else function is a no-op
      const transformFn =
        typeof mappingInfo.transformValueToFrontend === 'function'
          ? mappingInfo.transformValueToFrontend
          : x => x;

      // if frontendProperty is not defined, convert the key to camelCase and set it as the frontendProperty
      if (!mappingInfo.frontendProperty) {
        mappingInfo.frontendProperty = convertSnakeCaseToCamelCase(key);
      }

      // if frontendProperty is in the form a.b and so on, recursively set the nested properties
      if (mappingInfo.frontendProperty.includes('.')) {
        const nestedProperties = mappingInfo.frontendProperty.split('.');

        let nestedObject = nestedProperties.reduceRight((acc, prop, index) => {
          if (index === nestedProperties.length - 1) {
            return { [prop]: transformFn(sourceData[key]) };
          }
          return { [prop]: acc };
        }, {});

        if (!mappedAndTransformed[nestedProperties[0]]) {
          mappedAndTransformed[nestedProperties[0]] = {};
        }

        mappedAndTransformed = _.merge(nestedObject, mappedAndTransformed);
      } else {
        // set the transformed data
        mappedAndTransformed[mappingInfo.frontendProperty] = transformFn(sourceData[key]);
      }
    } else {
      // if discardUnmappedProperties is false, set the key to the transformed data
      if (!discardUnmappedProperties) {
        mappedAndTransformed[key] = sourceData[key];
      } else {
        // TODO log discarded properties
        //console.log(`Discarded property: ${key}`);
      }
    }
  });

  // if decorate function is defined, call it
  const decorated =
    typeof mapping.decorateFrontend === 'function'
      ? mapping.decorateFrontend(mappedAndTransformed)
      : mappedAndTransformed;

  return decorated;
};

export const mapToBackend = (sourceData, mapDefinitions) => {
  // assert that frontendData and mapping are not null
  if (!sourceData) throw new Error(`Mapper Source Data Error: ${sourceData}`);
  if (!mapDefinitions) throw new Error(`Mapper Mapping Error: ${mapDefinitions}`);

  // if prepare function is defined, call it
  const preparedData =
    typeof mapDefinitions.preBackendTransform === 'function'
      ? mapDefinitions.preBackendTransform(sourceData)
      : sourceData;

  let mappedData = { ...preparedData };

  Object.keys(mapDefinitions).forEach(backendKey => {
    // ignore the decorateFrontend and preBackendTransform functions
    if (['decorateFrontend', 'preBackendTransform', 'postBackendTransform'].includes(backendKey)) {
      return;
    }

    // get the mappingInfo for the key
    const mapInfo = mapDefinitions[backendKey];

    // get the frontend property name; if not defined, convert the key to camelCase
    const frontendProperty = mapInfo.frontendProperty || convertSnakeCaseToCamelCase(backendKey);

    // Use the transform function if defined, else function is a no-op
    const transformFn =
      typeof mapInfo.transformValueToBackend === 'function'
        ? mapInfo.transformValueToBackend
        : x => x;

    // set the transformed data
    if (_.get(sourceData, frontendProperty) !== undefined)
      mappedData[backendKey] = transformFn(_.get(sourceData, frontendProperty));
  });

  // delete any property that is not in the mapping (for good measure)
  Object.keys(mappedData).forEach(key => {
    if (!mapDefinitions[key]) {
      delete mappedData[key];
    }
  });

  // if postBackendTransform function is defined, call it
  if (typeof mapDefinitions.postBackendTransform === 'function') {
    mappedData = mapDefinitions.postBackendTransform(mappedData);
  }

  return mappedData;
};
