import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import { useFormikContext } from 'formik';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';
import { sortAscendingAlphaLabel } from 'shared/utilities/vehicle';

const VehiclePlaybackFormVehicleDropdown = () => {
  const { actions, state } = useVehiclePlaybackProviderContext();

  const {
    allVehicles,
    playbackRequestForm: { readOnly, selectedVehicleId },
  } = state;
  const { setPlaybackRequestFormAction } = actions;

  const formik = useFormikContext();

  useMakeFormikField({
    fieldName: 'selectedVehicleId',
    fieldValue: selectedVehicleId,
    touched: true,
    triggerValidation: true,
  });

  const defaultSelectedVehicleLabel = allVehicles?.find(v => v.vehicle_id === selectedVehicleId)
    ?.meta.label;

  const vehicleOptions =
    allVehicles
      ?.sort(sortAscendingAlphaLabel)
      .map(v => ({ label: v.meta.label, value: v.meta.label })) || [];
  const getSelectedVehicleId = label => {
    return allVehicles.find(v => v.meta.label === label).vehicle_id;
  };
  return (
    <FilteredDropdown
      title={'Vehicle'}
      placeholderLabel={readOnly ? defaultSelectedVehicleLabel : 'Select a Vehicle'}
      dropdownOptions={vehicleOptions}
      handleItemSelect={selectedItem => {
        if (selectedItem) {
          setPlaybackRequestFormAction({
            selectedVehicleId: getSelectedVehicleId(selectedItem.label),
          });
        } else {
          setPlaybackRequestFormAction({ selectedVehicleId: null });
        }
      }}
      defaultItem={null}
      styleOptions={{ disabled: readOnly }}
    />
  );
};

export default VehiclePlaybackFormVehicleDropdown;
