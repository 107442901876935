import { sortAscendingAlpha } from 'shared/utilities/general';

class DropdownOptions {
  constructor(options) {
    this.options = options;
  }

  getValueByLabel(label) {
    let value = this.options.find(item => item.label === label)?.value;
    if (value === undefined) {
      throw new Error('Value not found for label: ' + label);
    }

    return value;
  }

  getLabelByValue(value) {
    let label = this.options.find(item => item.value === value)?.label;
    if (label === undefined) {
      throw new Error('Label not found for value: ' + value);
    }

    return label;
  }

  getOptionByValue(value) {
    let option = this.options.find(item => item.value === value);
    if (option === undefined) {
      throw new Error('Options not found for value: ' + value);
    }

    return option;
  }

  getOptionByLabel(label) {
    let option = this.options.find(item => item.label === label);
    if (option === undefined) {
      throw new Error('Options not found for label: ' + label);
    }

    return option;
  }

  // sorted by default unless customSort is true, then we maintain passed in order
  getAllOptions(customSort) {
    return customSort
      ? this.options
      : this.options.sort((a, b) => sortAscendingAlpha(a.label, b.label));
  }

  getAllLabels() {
    return this.options.map(item => item.label).sort(sortAscendingAlpha);
  }

  getAllValues() {
    return this.options.map(item => item.value).sort(sortAscendingAlpha);
  }
}

export default DropdownOptions;
