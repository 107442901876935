import { maintenanceTypeConstants } from 'app/features/maintenance/data/maintenanceTypeConstants';
import { maintenanceTypeDropdownData } from 'app/features/maintenance/data/maintenanceTypeDropdownData';
import { TypeDropDownDiv } from 'shared/styles/components/Maintenance';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';
import { sortAscendingAlpha } from 'shared/utilities/general';

const MaintenanceTypeDropdown = ({ handleSelect }) => {
  const dropdownOptions = maintenanceTypeDropdownData
    .sort(sortAscendingAlpha)
    .filter(t => t.value !== maintenanceTypeConstants.DIAGNOSTIC); //dont let the user choose diagnostic as a maintenance type

  return (
    <>
      <h5>Choose a Maintenance Type</h5>
      <TypeDropDownDiv>
        <FilteredDropdown
          restrictedHeightResults
          dropdownOptions={dropdownOptions}
          title={'Type'}
          placeholderLabel={'Select a Type'}
          defaultItem={null}
          styleOptions={{
            hideClearX: true,
            required: true,
          }}
          clearOnSelection={true}
          handleItemSelect={selectedItem => {
            if (selectedItem) {
              handleSelect(selectedItem);
            }
          }}
        />
      </TypeDropDownDiv>
    </>
  );
};

export default MaintenanceTypeDropdown;
