import { useFormikContext } from 'formik';

import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import NewFilteredDropdown from 'app/shared/components/controls/FilteredDropdown/NewFilteredDropdown';
import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import { vehicleAddonOptions } from 'features/fleet/vehicles/constants/vehicleAddonDictionary';
import { FieldError } from 'shared/styles/components/Form';
import { DropdownWrapper } from 'shared/styles/components/ManageAlerts';

const AlertAddonDropdown = () => {
  const {
    state: { alert },
    actions: { updateAlertAddonAction },
  } = useAlertFormsProviderContext();

  const { alertDetails } = alert;
  const { alertAddonDropdownOption } = alertDetails.decorated;

  const formik = useFormikContext();

  let addonValue = alertAddonDropdownOption === null ? null : alertAddonDropdownOption.value;

  useMakeFormikField({
    fieldName: 'alertAddonField',
    fieldValue: addonValue,
    triggerValidation: true,
  });

  return (
    <DropdownWrapper alertAddon>
      <NewFilteredDropdown
        styleOptions={{
          disabled: formik.isSubmitting,
          hideClearX: true,
          required: true,
        }}
        title={'Addon'}
        placeholderLabel={'Select an addon'}
        setup={{
          dropdownOptions: vehicleAddonOptions,
          initialValue: addonValue,
          onSelect: ({ value }) => updateAlertAddonAction({ alertAddonValue: value }),
        }}
      />
      <FieldError>{formik.touched.alertAddonField && formik.errors.alertAddonField}</FieldError>
    </DropdownWrapper>
  );
};

export default AlertAddonDropdown;
