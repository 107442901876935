import { alertMethodConstants } from 'app/features/alerts/data/enums/alertMethodConstants';
import { alertTypeConstants } from 'app/features/alerts/data/enums/alertTypeConstants';
import { activeInactiveDropdownOptions } from 'app/features/alerts/data/ui/dropdown/activeInactiveDropdownOptions';
import { alertTypeDropdownOptions } from 'app/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';
import { getUnitOfMeasureDisplay } from 'app/features/alerts/services/mapping/helpers/getUnitOfMeasureDisplay';
import { mapRecipientUserAlertMethod } from 'app/features/alerts/services/mapping/helpers/mapRecipientUserAlertMethod';
import { offlineTimeUnitConverterToFrontend } from 'app/features/alerts/services/utilities/offlineTimeUnitConverterToFrontend';
import { addDisplayCommas } from 'app/features/maintenance/utilities/formatting';
import { getAggregateAlertMethodFromRecipientList } from 'app/shared/utilities/getAggregateAlertMethodFromRecipientList';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';
import { mapToFrontend } from 'app/shared/utilities/mapping/mapper';
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';

export const alertLogsMapping = {
  alert_id: {
    frontendProperty: 'alertId',
  },
  alert_type: {
    frontendProperty: 'alertType',
  },
  alert_name: {
    frontendProperty: 'alertName',
  },
  measured_value: {
    frontendProperty: 'measuredValue',
  },
  organization_id: {
    frontendProperty: 'organizationId',
  },

  trigger_value: {
    frontendProperty: 'triggerValue',
  },
  // display value associated with trigger value such as "MPH" or "Hours"
  // not always sent
  ui_unit_preference: {
    frontendProperty: 'unitOfMeasure',
  },
  vehicle_name: {
    frontendProperty: 'vehicleName',
  },

  test_mode_enabled: {
    frontendProperty: 'testModeEnabled',
  },
  test_mode_mute_alerts: {
    frontendProperty: 'testModeMuteAlerts',
  },

  timestamp: {
    frontendProperty: 'timestamp',
  },

  vehicle_id: {
    frontendProperty: 'vehicleId',
  },

  user_list: {
    frontendProperty: 'alertRecipients',

    transformValueToFrontend: user_list => {
      return user_list.map(u => {
        return {
          recipientUserName: u.userName,
          firstName: u.given_name,
          lastName: u.family_name,
          recipientUserAlertMethod: mapRecipientUserAlertMethod(u),
        };
      });
    },
    transformValueToBackend: alertRecipients => {
      return alertRecipients.map(ar => {
        return {
          username: ar.recipientUserName,
          email:
            ar.recipientUserAlertMethod === alertMethodConstants.EMAIL ||
            ar.recipientUserAlertMethod === alertMethodConstants.SMS_AND_EMAIL,
          sms:
            ar.recipientUserAlertMethod === alertMethodConstants.SMS ||
            ar.recipientUserAlertMethod === alertMethodConstants.SMS_AND_EMAIL,
        };
      });
    },
  },
};

export const mapAlertLogDataToFrontend = alertLog => {
  const mappedAlertLog = mapToFrontend(alertLog, alertLogsMapping);

  if (
    mappedAlertLog.alertType === alertTypeConstants.CHECK_ENGINE_LIGHT ||
    mappedAlertLog.alertType === alertTypeConstants.ADDON_ACTIVE
  ) {
    mappedAlertLog.triggerValue = activeInactiveDropdownOptions.getLabelByValue(
      mappedAlertLog.triggerValue,
    );
    mappedAlertLog.measuredValue = activeInactiveDropdownOptions.getLabelByValue(
      mappedAlertLog.measuredValue,
    );
  } else if (mappedAlertLog.alertType === alertTypeConstants.MAINTENANCE_DUE) {
    mappedAlertLog.triggerValue = 'Due For Work';
    mappedAlertLog.measuredValue = 'Due For Work';
  }
  mappedAlertLog.targetVehicleOrGroupName =
    mappedAlertLog.vehicleGroupName || mappedAlertLog.vehicleName || 'All Vehicles';

  mappedAlertLog.unitOfMeasureDisplay = getUnitOfMeasureDisplay(
    mappedAlertLog.unitOfMeasure,
    mappedAlertLog.alertType,
    offlineTimeUnitConverterToFrontend(mappedAlertLog.unitOfMeasure, mappedAlertLog.triggerValue),
  );

  mappedAlertLog.formattedUserDisplayNames = getUserNameDisplayFromUserList(
    mappedAlertLog.alertRecipients,
  );

  mappedAlertLog.aggregateAlertMethod = getAggregateAlertMethodFromRecipientList(
    mappedAlertLog.alertRecipients,
    true,
  );

  mappedAlertLog.formattedTime = formatISOTimestampToLongDateWithTime(mappedAlertLog.timestamp);

  mappedAlertLog.triggerValueDisplay = `${
    mappedAlertLog.alertType === alertTypeConstants.OFFLINE_TIME_EXCEEDS
      ? offlineTimeUnitConverterToFrontend(
          mappedAlertLog.unitOfMeasure,
          mappedAlertLog.triggerValue,
        )
      : mappedAlertLog.triggerValue
  } ${mappedAlertLog.unitOfMeasure ? mappedAlertLog.unitOfMeasureDisplay : ''}`;

  mappedAlertLog.measuredValueDisplay = `${
    mappedAlertLog.alertType === alertTypeConstants.OFFLINE_TIME_EXCEEDS
      ? offlineTimeUnitConverterToFrontend(
          mappedAlertLog.unitOfMeasure,
          mappedAlertLog.measuredValue,
        )
      : mappedAlertLog.measuredValue
  } ${
    mappedAlertLog.unitOfMeasure
      ? getUnitOfMeasureDisplay(
          mappedAlertLog.unitOfMeasure,
          mappedAlertLog.alertType,
          offlineTimeUnitConverterToFrontend(
            mappedAlertLog.unitOfMeasure,
            mappedAlertLog.measuredValue,
          ),
        )
      : ''
  }`;
  mappedAlertLog.alertTypeDisplay = alertTypeDropdownOptions.getLabelByValue(
    mappedAlertLog.alertType,
  );
  return mappedAlertLog;
};

export const mapAlertLogsDataToCsv = ({ alertLogs }) => {
  const alertLogsForExport = alertLogs.map(l => ({
    Timestamp: l.formattedTime,
    Vehicle: l.vehicleName,
    Condition: alertTypeDropdownOptions.getLabelByValue(l.alertType),
    Alert_Value: l.triggerValueDisplay,
    Measured_Value: addDisplayCommas(l.measuredValueDisplay),
    Recipients: l.formattedUserDisplayNames,
    Method: l.aggregateAlertMethod,
  }));
  return alertLogsForExport;
};
