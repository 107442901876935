import { useSelector } from 'react-redux';

import NavigationDropdownButton from 'app/core/navigation/components/NavigationDropdownButton';
import NavigationLink from 'app/core/navigation/components/NavigationLink';
import { getPermissions } from 'core/redux/user/selectors';
import { StyledNavigationListItem } from 'app/shared/styles/NavigationStyles';
import { shouldRenderItem } from 'app/core/navigation/utilities/navigation';

const NavigationItem = ({
  item,
  itemKey,
  sidebarIsCollapsed,
  activatedItemKey,
  selectedItemKey,
  handleNavigationItemClick,
  maintenanceDisabled,
  manageAlertsDisabled,
}) => {
  const userRole = useSelector(state => state.user.user.userRole);
  const permissions = useSelector(state => getPermissions(state.user, 'nav component'));
  const daysUntilExpiration = useSelector(state => state.ui.daysUntilExpiration);

  if (
    !shouldRenderItem({
      item,
      userRole,
      permissions,
      daysUntilExpiration,
      maintenanceDisabled,
      manageAlertsDisabled,
    })
  )
    return null;

  return (
    <StyledNavigationListItem>
      {item.link ? (
        <NavigationLink
          itemKey={itemKey}
          item={item}
          selectedItemKey={selectedItemKey}
          sidebarIsCollapsed={sidebarIsCollapsed}
          handleNavigationItemClick={handleNavigationItemClick}
        />
      ) : (
        <NavigationDropdownButton
          itemKey={itemKey}
          item={item}
          userRole={userRole}
          permissions={permissions}
          daysUntilExpiration={daysUntilExpiration}
          sidebarIsCollapsed={sidebarIsCollapsed}
          activatedItemKey={activatedItemKey}
          selectedItemKey={selectedItemKey}
          handleNavigationItemClick={handleNavigationItemClick}
          maintenanceDisabled={maintenanceDisabled}
          manageAlertsDisabled={manageAlertsDisabled}
        />
      )}
    </StyledNavigationListItem>
  );
};

export default NavigationItem;
