import { alertMethodConstants } from 'app/features/alerts/data/enums/alertMethodConstants';
import DropdownOptions from 'app/shared/utilities/dropdown/DropdownOptions';

const alertMethodDropdownOptions = new DropdownOptions([
  { value: alertMethodConstants.SMS, label: 'Text' },
  { value: alertMethodConstants.EMAIL, label: 'Email' },
  { value: alertMethodConstants.SMS_AND_EMAIL, label: 'Text and Email' },
  { value: alertMethodConstants.CUSTOM, label: 'Custom' },
]);

export default alertMethodDropdownOptions;
