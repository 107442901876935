import styled from 'styled-components';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';

export const FilteredDropdownSelectorDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 5px;
  color: ${colors.midnight};
  font-weight: bold;
  cursor: pointer;

  ${props =>
    props.filterDropdownContainer &&
    `
    width: 200px;
    padding: 5px 0px 2px 0px;
    border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  `}

  ${props =>
    props.narrowWidth &&
    `
    width: 110px;
    padding: 5px 0px 2px 0px;
    border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  `}
`;

export const FilteredDropdownSearchInput = styled.input`
  border: none;
  height: 100%;
  width: 100%;
  color: ${colors.midnight};
  fontsize: 1.1rem;
`;

export const FilteredDropdownPopupDiv = styled.div`
  position: absolute;
  background: ${colors.cotton};
  border-radius: 3px;
  box-shadow: 0 1px 51px rgba(0, 0, 0, 0.15);
  margin-right: 7px;
  margin-top: 10px;
  left: 0px;
  top: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 15px 5px 15px;
  width: 300px;
  height: 70px;

  @media ${device.large} {
    right: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 10px;
    width: 0;
    height: 0;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #fff;
  }
`;

export const FilteredDropdownSearchResultsList = styled.ul`
  width: 300px;
  position: absolute;
  top: 68px;
  left: 0px;
  border-radius: 3px;
  max-height: 180px;
  overflow: auto;
  box-shadow: 0 21px 21px rgba(0, 0, 0, 0.12);
  @media ${device.large} {
    max-height: 60vh;
  }

  ${props =>
    props.restrictHeight &&
    `
    max-height: 100px;
    @media ${device.large} {
      max-height: 290px;
  }

	`};
`;

export const FilteredDropdownSearchResultListItem = styled.li`
  list-style-type: 'none';
  color: #8a909c;
  font-size: 1.1rem;
  background-color: white;
  font-weight: 'normal';
  cursor: pointer;
  border-top: ${({ showTopBorder }) => (showTopBorder ? '1px solid #EEEFF0' : 'none')};
  border-bottom: 1px solid #eeeff0;
  padding: 18px 10px 18px 20px;
  background-color: ${({ highlight }) => (highlight ? 'lightgray' : 'white')};
`;

export const FilteredDropdownWrapper = styled.div`
  ${props =>
    props.disabled &&
    `
  opacity:.5;
  pointer-events: none;
}

`};
`;
