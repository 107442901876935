import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { getPermissions } from 'core/redux/user/selectors';
import { maintenanceStatusConstants } from 'app/features/maintenance/data/maintenanceStatusConstants';
import { getMaintenanceTypeDisplay } from 'app/features/maintenance/utilities/formatting';
import { vehicleAddonDictionary } from 'features/fleet/vehicles/constants/vehicleAddonDictionary';
import { useFetchVehiclesQuery } from 'features/fleet/vehicles/hooks/useFetchVehiclesQuery';
import VehicleOnlineTime from 'features/livemap/components/VehicleOnlineTime.js';
import EditVehicleAliasModal from 'features/livemap/modals/EditVehicleAliasModal';
import colors from 'shared/constants/colors';
import { permissionData } from 'shared/constants/users';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
  vehicleTypeViewData,
} from 'shared/constants/vehicle';
import useDynamicSvgImport from 'shared/hooks/useDynamicSvgImport';
import { TextButton } from 'shared/styles/components/Button';
import { DropdownLink, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import { TextLink } from 'shared/styles/components/Link';
import { CloseButton } from 'shared/styles/components/Modal';
import {
  ActionMenuBtnWrapper,
  AddonItem,
  DetailColumn,
  DetailColumnsContainer,
  IconWrapper,
  MapVehicleCardWrapper,
  ModalSection,
  SectionLabel,
  VehicleDetailLabel,
  VehicleDetailValue,
  VehicleIcon,
  VehicleLabel,
  VehicleNameAndIconDiv,
  VehicleNameAndIconInnerDiv,
  VehicleStatus,
  VehicleType,
  NoClickWrapper,
  MaintenanceStatusWrapper,
} from 'shared/styles/components/VehicleDetailCard';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import EditVehicleAliasItem from 'shared/ui/menus/EditVehicleAliasItem';
import ViewLogsMenuItem from 'shared/ui/menus/ViewLogsMenuItem';
import ActionMenu from 'shared/ui/table/ActionMenu';
import { convertAltitude, formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';
import { getHeading } from 'shared/utilities/vehicle';
import VehicleGroupTags from 'shared/widgets/VehicleGroupTags';
import { getOrganization } from 'core/redux/organizations/selectors';
import { maintenanceTypeConstants } from 'app/features/maintenance/data/maintenanceTypeConstants';

const DetailField = ({ label, value }) => (
  <div style={{ marginTop: '2px' }}>
    <VehicleDetailLabel>{label}:</VehicleDetailLabel>
    <VehicleDetailValue> {value}</VehicleDetailValue>
  </div>
);

const DetermineShowAlert = addon => {
  /* when we have the laert level dictionary we can replace the hard coded alert level in the next line */
  return addon.alert_level === 'High' && addon.is_active;
};

const displayVehicleStatus = vehicle => {
  if (vehicle.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id) {
    return colors.cherry;
  } else if (vehicle.onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id) {
    return colors.midnight;
  } else return colors.haze;
};

const MapVehicleDetailCard = ({ selectedVehicle, groups, handleRequestClose }) => {
  const userPermissions = useSelector(state => getPermissions(state.user));
  const organization = useSelector(state =>
    getOrganization(state, state.user.filteredOrganizationId),
  );
  const isFullScreenMapMode = useSelector(state => state.ui.fullscreenMapMode);
  const [vehicle, setVehicleWithMaintenance] = useState(selectedVehicle);
  const [maintenanceVehicles, setMaintenanceVehicles] = useState([]);
  const [firstFetch, setFirstFetch] = useState(null);

  // get maintenance properties for vehicles
  const [vehicleQuery, maintenanceVehiclesResults] = useFetchVehiclesQuery({
    key: 'MapVehicleDetailCard',
    autoRun: false,
    enableRefetchInterval: false,
    cachingEnabled: true,
    apiFlags: {
      addons: true,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: true,
      obd: true,
    },
  });

  useEffect(() => {
    if (!isFullScreenMapMode) {
      vehicleQuery.refetch();
    }
    setMaintenanceVehicles(maintenanceVehiclesResults);
  }, [selectedVehicle]);

  useEffect(() => {
    setFirstFetch(true);
  }, []);

  useEffect(() => {
    if (vehicle.maintenance && firstFetch) {
      setFirstFetch(false);
    }
  }, [vehicle]);

  useEffect(() => {
    // attach maintenance property to selected vehicle
    if (maintenanceVehicles) {
      const matchedVehicle = maintenanceVehicles.find(v => v.vehicle_id === vehicle.vehicle_id);
      selectedVehicle.maintenance = matchedVehicle?.maintenance;
      selectedVehicle.obd = matchedVehicle?.obd;
    }
    setVehicleWithMaintenance({ ...selectedVehicle });
  }, [selectedVehicle, maintenanceVehicles]);

  const vehicleImage = useDynamicSvgImport({
    importPath: vehicleTypeViewData[vehicle.meta.vehicle_type].icon,
  });
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showEditAliasModalData, setShowEditAliasModalData] = useState({ show: false });

  useEffect(() => {
    setShowActionMenu(
      userPermissions.includes(permissionData.viewanalytics) ||
        userPermissions.includes(permissionData.editfleet),
    );

    setShowEditAliasModalData({
      show: false,
      alias: vehicle.meta.alias,
    });
  }, []);

  const getMaintenanceTooltip = () => {
    let tooltip = [];
    vehicle.maintenance.forEach(event => {
      let suffix =
        event.maintenance_type === maintenanceTypeConstants.DIAGNOSTIC
          ? `occured on ${formatISOTimestampToLongDateWithTime(event.created_date)}`
          : `due at ${event.due_in}`;
      tooltip = tooltip + `${getMaintenanceTypeDisplay(event.maintenance_type)} ${suffix}\n`;
    });
    return tooltip;
  };
  return vehicleImage ? (
    <>
      <CloseButton
        onClick={handleRequestClose}
        style={{
          position: 'relative',
          width: 'auto',
          marginRight: 7,
          marginTop: 7,
          cursor: 'pointer',
        }}
      >
        <IconSvgComponent
          svgFileName={'x'}
          alt="Close"
          title="Close"
          svgStyle={{ width: '12px' }}
        />
      </CloseButton>
      <MapVehicleCardWrapper>
        <ModalSection withBorder topSection>
          <DetailColumnsContainer>
            <VehicleNameAndIconDiv>
              <VehicleIcon src={vehicleImage} />
              <VehicleNameAndIconInnerDiv>
                <VehicleLabel
                  title={
                    vehicle.meta.alias
                      ? `Alias: ${vehicle.meta.alias || ''}, Vehicle Name: ${vehicle.meta.label}`
                      : ` Vehicle Name: ${vehicle.meta.label}`
                  }
                >
                  {`${
                    vehicle.meta.alias
                      ? `${vehicle.meta.alias} (${vehicle.meta.label})`
                      : vehicle.meta.label
                  }`}
                </VehicleLabel>
                <VehicleType>{vehicleTypeViewData[vehicle.meta.vehicle_type].title}</VehicleType>
                {vehicle.searching_for_gps === undefined || vehicle.searching_for_gps ? (
                  <VehicleStatus>Searching for GPS signal...</VehicleStatus>
                ) : (
                  <VehicleStatus style={{ color: displayVehicleStatus(vehicle) }}>
                    {vehicle.meta.out_of_service ? (
                      'Out of Service'
                    ) : (
                      <>
                        {vehicleOnlineStatusViewData[vehicle.onlineStatus].title} /{' '}
                        {vehicleRespondingStatusViewData[vehicle.respondingStatus].title}
                      </>
                    )}
                  </VehicleStatus>
                )}
                <div>
                  <VehicleOnlineTime
                    isOnline={vehicle.onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id}
                    onlineStartTime={vehicle.gps.current_state_start_time}
                    lastSeen={vehicle.gps.timestamp_gps}
                  />
                  {showActionMenu && (
                    <ActionMenuBtnWrapper>
                      <ActionMenu>
                        <PermissionProtectedElement
                          requiredPermissions={[permissionData.viewanalytics]}
                        >
                          <SimpleDropdownItem>
                            <ViewLogsMenuItem vehicle={vehicle} />
                          </SimpleDropdownItem>
                        </PermissionProtectedElement>
                        <PermissionProtectedElement
                          requiredPermissions={[permissionData.editfleet]}
                        >
                          <SimpleDropdownItem>
                            <DropdownLink to={`/vehicle/${encodeURIComponent(vehicle.vehicle_id)}`}>
                              Edit Vehicle
                            </DropdownLink>
                          </SimpleDropdownItem>
                        </PermissionProtectedElement>
                        <PermissionProtectedElement
                          requiredPermissions={[permissionData.editfleet]}
                        >
                          <EditVehicleAliasItem
                            onMenuItemClick={() =>
                              setShowEditAliasModalData(prevState => ({
                                ...prevState,
                                show: true,
                              }))
                            }
                          ></EditVehicleAliasItem>
                        </PermissionProtectedElement>
                      </ActionMenu>
                    </ActionMenuBtnWrapper>
                  )}
                </div>
              </VehicleNameAndIconInnerDiv>
            </VehicleNameAndIconDiv>
          </DetailColumnsContainer>
        </ModalSection>
        <ModalSection withBorder main>
          <DetailColumnsContainer>
            <DetailColumn>
              <SectionLabel>Vehicle Details</SectionLabel>
            </DetailColumn>
            {organization.maintenanceEnabled && (
              <DetailColumn maintenance>
                <SectionLabel maintenance>
                  <>
                    {firstFetch && (
                      <MaintenanceStatusWrapper>Checking Maintenance...</MaintenanceStatusWrapper>
                    )}
                    {vehicle.maintenance?.some(
                      item =>
                        item.status === maintenanceStatusConstants.DUE_IN_SOON ||
                        item.status === maintenanceStatusConstants.DUE_FOR_WORK ||
                        item.status === maintenanceStatusConstants.CHECK_ENGINE,
                    ) ? (
                      <IconWrapper>
                        {vehicle.maintenance.some(
                          item =>
                            item.status === maintenanceStatusConstants.CHECK_ENGINE &&
                            !item.is_suppressed,
                        ) ? (
                          <>
                            <IconSvgComponent svgFileName="check-engine-mini" />
                            <TextButton maintenanceMapCard type="button">
                              <PermissionProtectedElement
                                requiredPermissions={[permissionData.editfleet]}
                                alternativeComponent={<NoClickWrapper>Check Engine</NoClickWrapper>}
                              >
                                {!isFullScreenMapMode ? (
                                  <TextLink
                                    to={`/maintenance/${vehicle.vehicle_id}`}
                                    title={getMaintenanceTooltip()}
                                  >
                                    Check Engine
                                  </TextLink>
                                ) : (
                                  <NoClickWrapper title={getMaintenanceTooltip()}>
                                    Check Engine
                                  </NoClickWrapper>
                                )}
                              </PermissionProtectedElement>
                            </TextButton>
                          </>
                        ) : (
                          <>
                            <IconSvgComponent svgFileName="maintenance-mini" />
                            <TextButton maintenanceMapCard type="button">
                              <PermissionProtectedElement
                                requiredPermissions={[permissionData.editfleet]}
                                alternativeComponent={
                                  <NoClickWrapper>Due for Maintenance</NoClickWrapper>
                                }
                              >
                                {!isFullScreenMapMode ? (
                                  <TextLink
                                    to={`/maintenance/${vehicle.vehicle_id}`}
                                    title={getMaintenanceTooltip()}
                                  >
                                    Due for Maintenance
                                  </TextLink>
                                ) : (
                                  <NoClickWrapper title={getMaintenanceTooltip()}>
                                    Due for Maintenance
                                  </NoClickWrapper>
                                )}
                              </PermissionProtectedElement>
                            </TextButton>
                          </>
                        )}
                      </IconWrapper>
                    ) : (
                      !firstFetch && (
                        <MaintenanceStatusWrapper>Maintenance Up to Date</MaintenanceStatusWrapper>
                      )
                    )}
                  </>
                </SectionLabel>
              </DetailColumn>
            )}
          </DetailColumnsContainer>
          <DetailColumnsContainer>
            <DetailColumn>
              <DetailField label={'Make'} value={vehicle.meta.make} />
              <DetailField label={'Model'} value={vehicle.meta.model} />
              {vehicle.obd?.fuel_percentage && (
                <DetailField
                  label={'Fuel Percentage'}
                  value={vehicle.obd?.fuel_percentage ? vehicle.obd.fuel_percentage + '%' : null}
                />
              )}
            </DetailColumn>
            <DetailColumn>
              <DetailField label={'Year'} value={vehicle.meta.mfg_year} />
              <DetailField label={'License Plate'} value={vehicle.meta.license_plate} />
            </DetailColumn>
          </DetailColumnsContainer>
        </ModalSection>
        <ModalSection withBorder>
          <SectionLabel>Add-on Features</SectionLabel>
          {vehicle.addons.map(addon => (
            <DetailColumnsContainer>
              <DetailColumn>
                <AddonItem alert={DetermineShowAlert(addon)} key={addon.type}>
                  {vehicleAddonDictionary[addon.type]?.displayName}
                </AddonItem>
              </DetailColumn>
              <DetailColumn>
                <div>
                  <VehicleDetailLabel>Status: </VehicleDetailLabel>
                  <AddonItem alert={DetermineShowAlert(addon)}>
                    {addon.is_active ? ' Active' : ' Not Active'}
                  </AddonItem>
                </div>
              </DetailColumn>
            </DetailColumnsContainer>
          ))}
        </ModalSection>
        <ModalSection withBorder>
          <SectionLabel>Location Details</SectionLabel>
          <DetailColumnsContainer>
            <DetailColumn
              title={
                vehicle.gps.last_speed_source === 'OBD'
                  ? 'Speed determined by OBD2 Harness'
                  : 'Speed determined by GPS'
              }
            >
              <DetailField
                label={'Speed'}
                value={`${vehicle.gps.speed ? vehicle.gps.speed : 0}
                  MPH,
                  ${getHeading(vehicle.gps.heading)}`}
              />
            </DetailColumn>
            <DetailColumn>
              <DetailField
                label={'Elevation'}
                value={convertAltitude(vehicle.gps.altitude) + ' FT'}
              />
            </DetailColumn>
          </DetailColumnsContainer>
          <DetailField
            label={'Coordinates'}
            value={`${vehicle.gps.latitude}, ${vehicle.gps.longitude}`}
          />
        </ModalSection>
        <ModalSection>
          <SectionLabel>Associated Groups</SectionLabel>
          <VehicleGroupTags vehicle={vehicle} groups={groups} />
        </ModalSection>
      </MapVehicleCardWrapper>
      <EditVehicleAliasModal
        vehicle={vehicle}
        show={showEditAliasModalData.show}
        handleCloseModal={() => {
          return setShowEditAliasModalData(prevState => ({ ...prevState, show: false }));
        }}
      />
    </>
  ) : null;
};
export default MapVehicleDetailCard;
