import { DateTime } from 'luxon';

const getNumberSuffix = num => {
  if (num === '11' || num === '12' || num === '13') return 'th';

  let lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case '1':
      return 'st';
    case '2':
      return 'nd';
    case '3':
      return 'rd';
    default:
      return 'th';
  }
};

export const getMonthDropdownOptions = () =>
  Array(12)
    .fill(1)
    .map((x, y) => ({
      label: DateTime.fromObject({ month: x + y }).toFormat('MMMM'),
      value: x + y,
    }));

export const getCurrentYearAsInteger = () => {
  return +DateTime.now().toFormat('yyyy');
};

export const getCurrentMonthAsInteger = ({ offset } = {}) => {
  return +DateTime.now()
    .plus({ months: offset })
    .toFormat('MM');
};

export const getUnixTimestampFromIsoTimestamp = timestamp => {
  return DateTime.fromISO(timestamp).toUnixInteger();
};

export const getDifferenceInMonths = ({ startMonth, startYear, endMonth, endYear }) => {
  return +DateTime.fromObject({ month: endMonth, year: endYear })
    .diff(DateTime.fromObject({ month: startMonth, year: startYear }))
    .toFormat('MM');
};
export const getAMonthAgo = () => {
  return DateTime.now()
    .minus({ months: 1 })
    .toISO();
};

export const ISOTimeFormatted = timestamp => {
  return DateTime.fromISO(timestamp);
};

// gets last n years, including given year and excluding any year before minYear in ascending order
export const getLastNYears = ({ beforeAndIncludingYear, numYears, minYear }) => {
  // anything before min year is rejected
  if (beforeAndIncludingYear < minYear) return [];

  let lastNYears = [];

  // add last n years to lastNYears
  for (let i = 0; i < numYears; i++) {
    lastNYears.push(beforeAndIncludingYear--);
  }

  // sort ascending
  // chop off the years before minYear
  // return as string
  return lastNYears
    .sort()
    .slice(lastNYears.indexOf(minYear) === -1 ? 0 : lastNYears.indexOf(minYear))
    .map(y => `${y}`);
};

export const getTimeOptionsIn15MinuteIncrements = () => {
  var startTime = DateTime.fromFormat('12:00 am', 'h:mm a');
  var endTime = DateTime.fromFormat('11:59 pm', 'h:mm a');
  var times = [];

  while (startTime <= endTime) {
    times.push(startTime.toFormat('h:mm a').toLowerCase());
    startTime = startTime.plus({ minutes: 15 });
  }

  return times;
};

export const getShortDateInIsoFormat = date => {
  if (!date) return null;
  return DateTime.fromFormat(date, 'MM/dd/yyyy').toISO();
};

export const getLongDateInIsoFormat = date => {
  return DateTime.fromFormat(date.toString().substring(0, 15), 'EEE MMM dd yyyy').toISO();
};

export const getStartOfDayForDateTimeInUtc = date => {
  return DateTime.fromISO(date)
    .startOf('day')
    .toUTC()
    .toISO();
};

export const getUtc = date => {
  return DateTime.fromISO(date)
    .toUTC()
    .toISO();
};

export const getEndOfDayForDateTimeInUtc = date => {
  return DateTime.fromISO(date)
    .endOf('day')
    .toUTC()
    .toISO();
};

export const getEndOfMonthDateStringForDateTime = date => {
  return DateTime.fromISO(date)
    .endOf('month')
    .toFormat('MM-dd-yyyy');
};

export const getStartOfMonthDateStringForDateTime = date => {
  return DateTime.fromISO(date)
    .startOf('month')
    .toFormat('MM-dd-yyyy');
};

export const getStartOfMonthDateStringForDateTimeFromObject = ({ startMonth, startYear }) => {
  return DateTime.fromObject({
    day: 1,
    month: startMonth,
    year: startYear,
  })
    .startOf('month')
    .toFormat('MM/dd/yyyy');
};

export const getEndOfMonthDateStringForDateTimeFromObject = ({ endMonth, endYear }) => {
  return DateTime.fromObject({
    day: 1,
    month: endMonth,
    year: endYear,
  })
    .endOf('month')
    .toFormat('MM/dd/yyyy');
};

export const formatISOTimestampFromUnixTimestamp = timestamp => {
  return DateTime.fromSeconds(+timestamp).toISO();
};

export const getLocalTimeStringFromZuluTimeString = timestamp => {
  const localTime = new Date(timestamp);

  return localTime.toString();
};

export const formatISOTimestampToDescriptiveLog = timestamp => {
  return `${formatISOTimestampToShortDate(timestamp)} at ${formatISOTimestampToStandardTime(
    timestamp,
  )}`;
};

export const formatISOTimestampToShortDate = timestamp => {
  return DateTime.fromISO(timestamp).toFormat('MM/dd/yyyy');
};
export const formatISOTimestampToGivenFormat = ({ timestamp, format }) => {
  return DateTime.fromISO(timestamp).toFormat(format);
};

export const formatISOTimestampToLongDate = timestamp => {
  let day = DateTime.fromISO(timestamp).toFormat('d');
  let daySuffix = getNumberSuffix(day);
  let longDateString = DateTime.fromISO(timestamp).toFormat('MMMM d, yyyy');
  let formattedDateString = longDateString.replace(day, `${day}${daySuffix}`);
  return formattedDateString;
};

export const getLongDateFromUnixTimestamp = timestamp => {
  return formatISOTimestampToLongDateWithTime(DateTime.fromMillis(timestamp).toISO());
};

export const formatISOTimestampToLongDateWithTime = timestamp => {
  let day = DateTime.fromISO(timestamp).toFormat('d');
  let daySuffix = getNumberSuffix(day);
  let longDateStringDate = DateTime.fromISO(timestamp).toFormat('MMMM d, yyyy');
  let longDateStringTime = DateTime.fromISO(timestamp)
    .toFormat('h:mm:ss a')
    .toLowerCase();
  let formattedDateString = `${longDateStringDate.replace(
    day,
    `${day}${daySuffix}`,
  )} at ${longDateStringTime}`;
  return formattedDateString;
};

export const formatISOTimestampToLongDateWithoutYear = timestamp => {
  let day = DateTime.fromISO(timestamp).toFormat('d');
  let daySuffix = getNumberSuffix(day);
  let longDateString = DateTime.fromISO(timestamp).toFormat('EEEE, MMMM d');
  let formattedDateString = longDateString.replace(day, `${day}${daySuffix}`);
  return formattedDateString;
};

export const formatISOTimestampToStandardTime = timestamp => {
  return DateTime.fromISO(timestamp).toFormat('h:mm:ss a');
};

export const getDurationFromStartAndEndTimes = ({ startTime, endTime }) => {
  if (startTime === null || endTime === null) return 0;

  let start = DateTime.fromFormat(startTime, 'h:mm a');
  let end = DateTime.fromFormat(endTime, 'h:mm a');

  if (start > end) {
    end = end.plus({ days: 1 });
  }

  let duration = end.diff(start, 'minutes');
  return +duration.toFormat('mm');
};
export const getNow = () => {
  return DateTime.now().toISO();
};
export const getHoursSince = ({ endDateTime, startDateTime }) => {
  let duration = DateTime.fromISO(endDateTime).diff(DateTime.fromISO(startDateTime), 'hours');
  return duration.toFormat('hh');
};

export const getDaysSince = ({ fromDateTime, toDateTime }) => {
  let duration = DateTime.fromISO(toDateTime).diff(DateTime.fromISO(fromDateTime), 'days');

  return duration.toFormat('dd');
};

export const getMonthsSince = ({ fromDateTime, toDateTime }) => {
  let duration = DateTime.fromISO(toDateTime).diff(DateTime.fromISO(fromDateTime), 'months');

  return duration.toFormat('MM');
};

export const getExpirationTime = ({ expiresAtDate, expiresAtTime, timeZone }) => {
  let dateString = `${expiresAtDate}, ${expiresAtTime}`;
  let dateTime = DateTime.fromFormat(dateString, 'M/d/yyyy, h:mm a', { zone: timeZone })
    .toUTC()
    .toISO();
  return dateTime;
};

export const getEndTimeFromStartTimeAndDuration = ({ fromTime, duration }) => {
  return DateTime.fromFormat(fromTime, 'h:mm a')
    .plus({ minutes: duration })
    .toFormat('h:mm a')
    .toLowerCase();
};
export const getEndDateTimeFromStartTimeAndDurationInSeconds = ({ startDateTime, duration }) => {
  return DateTime.fromISO(startDateTime)
    .plus({ seconds: duration })
    .toISO();
};
export const getSecondsFromStartTime = ({ fromTime, toTime }) => {
  if (!toTime) {
    toTime = DateTime.now();
  } else {
    toTime = DateTime.fromISO(toTime);
  }
  return +toTime.diff(DateTime.fromISO(fromTime), 'seconds').toFormat('ss');
};
export const addHoursToDateTime = ({ dateTime, hours }) => {
  return DateTime.fromISO(dateTime)
    .plus({ hours })
    .toISO();
};

export const getYesterday = (startTime, duration) => {
  return DateTime.now()
    .minus({ days: 1 })
    .startOf('day')
    .toISO();
};

export const getFutureDate = daysInFuture => {
  return DateTime.now()
    .plus({ days: daysInFuture })
    .startOf('day')
    .toISO();
};

export const subractMinutesFromTimestamp = ({ startTime, change }) => {
  return DateTime.fromISO(startTime)
    .minus({ minutes: change })
    .toUTC()
    .toISO();
};

export const getShortDateFromDatePicker = day => {
  return formatISOTimestampToShortDate(DateTime.fromJSDate(day).toISO());
};

export const getTimeString = date => {
  if (!date) {
    return 'Unknown';
  }
  const d = new Date(date);
  const hour = d.getHours();
  const ampmHour = hour > 12 ? hour % 12 : hour;
  return `${ampmHour}:${(d.getMinutes() < 10 ? '0' : '') + d.getMinutes()} ${
    hour > 11 ? 'PM' : 'AM'
  } ${(d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1)}/${(d.getDate() < 10 ? '0' : '') +
    d.getDate()}`;
};

export const convertAltitude = altitude => {
  return Math.round(altitude * 3.28084 * 100) / 100;
};

export const convertTimeZone = timeZone => {
  if (timeZone == 'America/New_York') {
    return 'EST';
  } else if (timeZone == 'America/Chicago') {
    return 'CST';
  } else if (timeZone == 'America/Denver') {
    return 'MST';
  } else if (timeZone == 'America/Phoenix') {
    return 'MST';
  } else if (timeZone == 'America/Los_Angeles') {
    return 'PST';
  } else if (timeZone == 'America/Anchorage') {
    return 'AST';
  } else if (timeZone == 'America/Honolulu') {
    return 'HST';
  } else return timeZone;
};

export const convertDuration = (duration, ignoreSeconds) => {
  var ignoreSeconds = ignoreSeconds;
  var minutes = Math.floor(duration / 60);
  var seconds = duration % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  var days = Math.floor(hours / 24);
  hours = hours % 24;
  var readableTime = '';
  if (duration > 0) {
    if (days > 0) {
      readableTime += days + ' days ';
    }
    if (hours > 0) {
      readableTime += hours + ' hours ';
    }
    if (minutes > 0) {
      readableTime += minutes + ' minutes ';
    }
    if (!ignoreSeconds) {
      readableTime += seconds + ' seconds ';
    }
    if (ignoreSeconds) {
      if (days < 1 && hours < 1 && minutes < 1) {
        readableTime += 'Less than a minute';
      }
    }
    return readableTime;
  } else {
    return 0;
  }
};

export const convertGeofenceDuration = duration => {
  var hours = Math.floor(duration / 60);
  var minutes = duration % 60;
  var readableTime = '';
  if (duration > 0) {
    if (hours > 0) {
      readableTime += hours + ' hrs ';
    }
    if (minutes > 0) {
      readableTime += minutes + ' min ';
    }
    return readableTime;
  } else {
    return 'N/A';
  }
};

export const getCurrentHour = () => {
  const currentTime = DateTime.now();
  return currentTime.hour;
};

export const getToday = () => {
  return DateTime.now()
    .startOf('day')
    .toISO();
};
