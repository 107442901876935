import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import {
  SimpleDropdownItem,
  SimpleDropdownBtn,
  DropdownLink,
} from 'shared/styles/components/DropdownMenu';
import { Badge } from 'shared/styles/components/Badge';
import {
  debouncedFetchOrganizations as fetchOrganizations,
  activateOrganization,
} from 'core/redux/organizations/actions';
import { toggleDeactivateOrganizationModal } from 'core/redux/ui/actions';
import useAPIData from 'shared/hooks/useAPIData';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import ModalPortal from 'shared/modals/ModalPortal';
import OrganizationForm from 'shared/modals/OrganizationForm';
import ActionMenu from 'shared/ui/table/ActionMenu';
import { createOrganization, editOrganization } from 'core/redux/organizations/actions';
import { TableSpinner } from 'shared/styles/components/Spinner';
import { closeGlobalModal } from 'core/redux/ui/actions';
import { customerDropdownOptions } from 'shared/constants/organizations';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

/**
 * @ Styled Components
 */
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { ActionButtonWithIcon, ActionButtonText } from 'shared/styles/components/Button';

const OrganizationsPage = ({
  organizations,
  fetchOrganizations,
  createOrganization,
  editOrganization,
  toggleDeactivateOrganizationModal,
  activateOrganization,
  userRole,
  closeGlobalModal,
}) => {
  const organizationsLoaded = useAPIData({ fetchAPIData: fetchOrganizations });
  const [organizationList, setOrganizationList] = useState(null);
  const [listingCount, setListingCount] = useState(organizations?.length);

  useEffect(() => {
    setOrganizationList(organizations);
    setListingCount(organizations?.length);
  }, [organizations]);

  const handleToggleDeactivateOrganizationModal = ({ organizationId }) => {
    toggleDeactivateOrganizationModal(organizationId);
  };
  const toggleActiveStateHandler = (organization, index) => {
    const { active, organizationId } = organization;
    return event => {
      event.preventDefault();
      active
        ? handleToggleDeactivateOrganizationModal({ organizationId, index })
        : activateOrganization(organization);
    };
  };

  /** Modal State & Handlers  **/
  const defaultModalState = {
    isOpen: false,
    mode: null,
    organization: null,
    confirmHandler: null,
  };
  const [organizationModal, updateOrganizationModalProps] = useState(defaultModalState);

  const handleOpenCreateModal = () =>
    updateOrganizationModalProps({
      isOpen: true,
      mode: OrganizationForm.MODE.CREATE,
      confirmHandler: createOrganization,
      userRole: userRole,
    });
  const handleOpenEditModal = (event, organizationId) => {
    event.preventDefault();
    const organization = organizations.find(
      organization => organization.organizationId === organizationId,
    );

    updateOrganizationModalProps({
      organization,
      isOpen: true,
      mode: OrganizationForm.MODE.EDIT,
      confirmHandler: editOrganization,
      userRole: userRole,
    });
  };
  const handleCloseModal = () => {
    closeGlobalModal();
    updateOrganizationModalProps({ defaultModalState });
  };

  /** Table Column Definitions **/
  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
      searchable: true,
      className: 'name',
      render(value, row) {
        return (
          <span
            className="vehicleNameAndIcon"
            title={
              'Organization Name: ' +
              (row?.orgdata?.organizationName ? row?.orgdata?.organizationName : value)
            }
          >
            {row.loading_status ? (
              <TableSpinner />
            ) : (
              <IconSvgComponent svgFileName={'organizations'} alt="Organizations" />
            )}

            <Link
              to={`${encodeURIComponent(row.organizationId)}`}
              style={{ textDecoration: 'none' }}
            >
              <TruncatedTextWithWrap orgTable>{value}</TruncatedTextWithWrap>
            </Link>
          </span>
        );
      },
    },
    {
      title: 'Contact Email',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      searchable: true,
      render(value, row) {
        return (
          <TruncatedTextWithWrap
            orgTable
            title={
              'Contact Email: ' + (row?.orgdata?.contactEmail ? row?.orgdata?.contactEmail : value)
            }
          >
            {value}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Customer Type',
      dataIndex: 'customerType',
      key: 'customerType',
      sortable: true,
      render(value, row) {
        const specifyType = row.customerType
          ? customerDropdownOptions.find(ct => ct.value === row.customerType)?.label
          : 'Not Specified';
        return <span title={'Customer Type: ' + specifyType}>{specifyType}</span>;
      },
    },
    {
      title: 'Contact Phone',
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      searchable: true,
      render(value, row) {
        return (
          <span
            title={
              'Contact Phone: ' + (row?.orgdata?.contactPhone ? row?.orgdata?.contactPhone : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDateValue',
      searchIndex: 'startDate',
      key: 'startDate',
      searchable: true,
      render(value, row) {
        return (
          <span
            title={
              'Start Date: ' + (row?.orgdata?.startDate ? row?.orgdata?.startDate : row.startDate)
            }
          >
            {row.startDate}
          </span>
        );
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDateValue',
      searchIndex: 'endDate',
      key: 'endDate',
      searchable: true,
      render(value, row) {
        return (
          <span
            title={'End Date: ' + (row?.orgdata?.endDate ? row?.orgdata?.endDate : row.endDate)}
          >
            {row.endDate}
          </span>
        );
      },
    },
    {
      title: 'Vehicle Count',
      dataIndex: 'vehicleCount',
      key: 'vehicleCount',
      width: '5%',
      render(value, row) {
        return (
          <span
            title={
              'Vehicle Count: ' + (row?.orgdata?.vehicleCount ? row?.orgdata?.vehicleCount : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      searchable: false,
      render: (value, org) => (
        <Badge active={org.active}>
          <span title="Status">{value}</span>
        </Badge>
      ),
    },
    {
      title: 'HAAS Integration',
      dataIndex: 'haasEnabled',
      key: 'haasEnabled',
      searchable: false,
      render: (value, row) =>
        row.haasEnabled &&
        (row.haasSetup ? (
          <IconSvgComponent
            svgFileName={'check-success'}
            alt="HAAS Enabled"
            title="HAAS Integration Enabled"
          />
        ) : (
          <IconSvgComponent
            svgFileName={'check-pending'}
            alt="HAAS Pending"
            title="HAAS Integration Pending"
          />
        )),
    },
    {
      title: 'GTT Integration',
      dataIndex: 'gttEnabled',
      key: 'gttEnabled',
      searchable: false,
      render: (value, row) =>
        row.gttEnabled &&
        (row.gttSetup ? (
          <IconSvgComponent
            svgFileName={'check-success'}
            alt="GTT Enabled"
            title="GTT Integration Enabled"
          />
        ) : (
          <IconSvgComponent
            svgFileName={'check-pending'}
            alt="GTT Pending"
            title="GTT Integration Pending"
          />
        )),
    },
    {
      title: 'action',
      dataIndex: '',
      className: 'action',
      key: 'action',
      render: (row, index) => {
        return (
          <ActionMenu>
            <SimpleDropdownItem>
              <SimpleDropdownBtn onClick={e => handleOpenEditModal(e, row.organizationId)}>
                Edit
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
            <SimpleDropdownItem>
              <DropdownLink to={`${encodeURIComponent(row.organizationId)}`}>
                Manage Users
              </DropdownLink>
            </SimpleDropdownItem>

            <SimpleDropdownItem>
              <SimpleDropdownBtn
                onClick={toggleActiveStateHandler(row?.orgdata ? row.orgdata : row, index)}
              >
                {row.active ? 'Deactivate' : 'Activate'}
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
          </ActionMenu>
        );
      },
    },
  ];

  return organizationList ? (
    <PageListWrapper>
      <PageListHead>
        <PageListTitle>Organizations</PageListTitle>
        <ActionButtonWithIcon create onClick={handleOpenCreateModal}>
          <IconSvgComponent svgFileName="plus-simple" alt="Create" />
          <ActionButtonText>Create Organization</ActionButtonText>
        </ActionButtonWithIcon>
      </PageListHead>
      <h5>
        {listingCount === organizations?.length
          ? `Total of ${listingCount} Organization${listingCount !== 1 ? 's' : ''}`
          : `${listingCount} of ${organizations?.length} Organization${
              organizations?.length !== 1 ? 's' : ''
            }`}
      </h5>
      <SortablePaginatedTable
        className="table-simple"
        columns={columns}
        tableData={organizationList}
        rowKey={record => record.organizationId}
        scroll={{ x: false, y: false }}
        setListingCount={setListingCount}
      />
      {organizationModal.isOpen && (
        <ModalPortal onRequestClose={handleCloseModal}>
          <OrganizationForm
            mode={organizationModal.mode}
            organization={organizationModal.organization}
            confirmHandler={organizationModal.confirmHandler}
            userRole={userRole}
          />
        </ModalPortal>
      )}
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  state => ({
    organizations: state.organizations.organizations,
    currentUserUsername: state.user.user.username,
    userRole: state.user.user.userRole,
  }),
  dispatch => ({
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    createOrganization: organization => dispatch(createOrganization(organization)),
    editOrganization: organization => dispatch(editOrganization(organization)),
    toggleDeactivateOrganizationModal: organizationId =>
      dispatch(toggleDeactivateOrganizationModal({ organizationId })),
    activateOrganization: organization => dispatch(activateOrganization(organization)),
    closeGlobalModal: () => dispatch(closeGlobalModal()),
  }),
)(OrganizationsPage);
