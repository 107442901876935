export const Alabama = 'Alabama';
export const Alaska = 'Alaska';
export const Arizona = 'Arizona';
export const Arkansas = 'Arkansas';
export const California = 'California';
export const Colorado = 'Colorado';
export const Connecticut = 'Connecticut';
export const Delaware = 'Delaware';
export const DistrictOfColumbia = 'DistrictOfColumbia';
export const Florida = 'Florida';
export const Georgia = 'Georgia';
export const Hawaii = 'Hawaii';
export const Idaho = 'Idaho';
export const Illinois = 'Illinois';
export const Indiana = 'Indiana';
export const Iowa = 'Iowa';
export const Kansas = 'Kansas';
export const Kentucky = 'Kentucky';
export const Louisiana = 'Louisiana';
export const Maine = 'Maine';
export const Maryland = 'Maryland';
export const Massachusetts = 'Massachusetts';
export const Michigan = 'Michigan';
export const Minnesota = 'Minnesota';
export const Mississippi = 'Mississippi';
export const Missouri = 'Missouri';
export const Montana = 'Montana';
export const Nebraska = 'Nebraska';
export const Nevada = 'Nevada';
export const NewHampshire = 'NewHampshire';
export const NewJersey = 'NewJersey';
export const NewMexico = 'NewMexico';
export const NewYork = 'NewYork';
export const NorthCarolina = 'NorthCarolina';
export const NorthDakota = 'NorthDakota';
export const Ohio = 'Ohio';
export const Oklahoma = 'Oklahoma';
export const Oregon = 'Oregon';
export const Pennsylvania = 'Pennsylvania';
export const RhodeIsland = 'RhodeIsland';
export const SouthCarolina = 'SouthCarolina';
export const SouthDakota = 'SouthDakota';
export const Tennessee = 'Tennessee';
export const Texas = 'Texas';
export const Utah = 'Utah';
export const Vermont = 'Vermont';
export const Virginia = 'Virginia';
export const Washington = 'Washington';
export const WestVirginia = 'WestVirginia';
export const Wisconsin = 'Wisconsin';
export const Wyoming = 'Wyoming';

export const stateOptions = {
  [Alaska]: {
    id: 1,
    title: 'AK',
    label: 'AK',
    value: 'AK',
    isChecked: false,
  },
  [Alabama]: {
    id: 0,
    title: 'AL',
    label: 'AL',
    value: 'AL',
    isChecked: false,
  },

  [Arkansas]: {
    id: 3,
    title: 'AR',
    label: 'AR',
    value: 'AR',
    isChecked: false,
  },
  [Arizona]: {
    id: 2,
    title: 'AZ',
    label: 'AZ',
    value: 'AZ',
    isChecked: false,
  },
  [California]: {
    id: 4,
    title: 'CA',
    label: 'CA',
    value: 'CA',
    isChecked: false,
  },
  [Colorado]: {
    id: 5,
    title: 'CO',
    label: 'CO',
    value: 'CO',
    isChecked: false,
  },
  [Connecticut]: {
    id: 6,
    title: 'CT',
    label: 'CT',
    value: 'CT',
    isChecked: false,
  },
  [DistrictOfColumbia]: {
    id: 8,
    title: 'DC',
    label: 'DC',
    value: 'DC',
    isChecked: false,
  },
  [Delaware]: {
    id: 7,
    title: 'DE',
    label: 'DE',
    value: 'DE',
    isChecked: false,
  },
  [Florida]: {
    id: 9,
    title: 'FL',
    label: 'FL',
    value: 'FL',
    isChecked: false,
  },
  [Georgia]: {
    id: 10,
    title: 'GA',
    label: 'GA',
    value: 'GA',
    isChecked: false,
  },
  [Hawaii]: {
    id: 11,
    title: 'HI',
    label: 'HI',
    value: 'HI',
    isChecked: false,
  },
  [Iowa]: {
    id: 15,
    title: 'IA',
    label: 'IA',
    value: 'IA',
    isChecked: false,
  },
  [Idaho]: {
    id: 12,
    title: 'ID',
    label: 'ID',
    value: 'ID',
    isChecked: false,
  },
  [Illinois]: {
    id: 13,
    title: 'IL',
    label: 'IL',
    value: 'IL',
    isChecked: false,
  },
  [Indiana]: {
    id: 14,
    title: 'IN',
    label: 'IN',
    value: 'IN',
    isChecked: false,
  },

  [Kansas]: {
    id: 16,
    title: 'KS',
    label: 'KS',
    value: 'KS',
    isChecked: false,
  },
  [Kentucky]: {
    id: 17,
    title: 'KY',
    label: 'KY',
    value: 'KY',
    isChecked: false,
  },
  [Louisiana]: {
    id: 18,
    title: 'LA',
    label: 'LA',
    value: 'LA',
    isChecked: false,
  },
  [Massachusetts]: {
    id: 21,
    title: 'MA',
    label: 'MA',
    value: 'MA',
    isChecked: false,
  },
  [Maryland]: {
    id: 20,
    title: 'MD',
    label: 'MD',
    value: 'MD',
    isChecked: false,
  },
  [Maine]: {
    id: 19,
    title: 'ME',
    label: 'ME',
    value: 'ME',
    isChecked: false,
  },

  [Michigan]: {
    id: 22,
    title: 'MI',
    label: 'MI',
    value: 'MI',
    isChecked: false,
  },
  [Minnesota]: {
    id: 23,
    title: 'MN',
    label: 'MN',
    value: 'MN',
    isChecked: false,
  },
  [Missouri]: {
    id: 25,
    title: 'MO',
    label: 'MO',
    value: 'MO',
    isChecked: false,
  },
  [Mississippi]: {
    id: 24,
    title: 'MS',
    label: 'MS',
    value: 'MS',
    isChecked: false,
  },
  [Montana]: {
    id: 26,
    title: 'MT',
    label: 'MT',
    value: 'MT',
    isChecked: false,
  },
  [NorthCarolina]: {
    id: 33,
    title: 'NC',
    label: 'NC',
    value: 'NC',
    isChecked: false,
  },
  [NorthDakota]: {
    id: 34,
    title: 'ND',
    label: 'ND',
    value: 'ND',
    isChecked: false,
  },
  [Nebraska]: {
    id: 27,
    title: 'NE',
    label: 'NE',
    value: 'NE',
    isChecked: false,
  },
  [NewHampshire]: {
    id: 29,
    title: 'NH',
    label: 'NH',
    value: 'NH',
    isChecked: false,
  },
  [NewJersey]: {
    id: 30,
    title: 'NJ',
    label: 'NJ',
    value: 'NJ',
    isChecked: false,
  },
  [NewMexico]: {
    id: 31,
    title: 'NM',
    label: 'NM',
    value: 'NM',
    isChecked: false,
  },
  [Nevada]: {
    id: 28,
    title: 'NV',
    label: 'NV',
    value: 'NV',
    isChecked: false,
  },
  [NewYork]: {
    id: 32,
    title: 'NY',
    label: 'NY',
    value: 'NY',
    isChecked: false,
  },
  [Ohio]: {
    id: 35,
    title: 'OH',
    label: 'OH',
    value: 'OH',
    isChecked: false,
  },
  [Oklahoma]: {
    id: 36,
    title: 'OK',
    label: 'OK',
    value: 'OK',
    isChecked: false,
  },
  [Oregon]: {
    id: 37,
    title: 'OR',
    label: 'OR',
    value: 'OR',
    isChecked: false,
  },
  [Pennsylvania]: {
    id: 38,
    title: 'PA',
    label: 'PA',
    value: 'PA',
    isChecked: false,
  },
  [RhodeIsland]: {
    id: 39,
    title: 'RI',
    label: 'RI',
    value: 'RI',
    isChecked: false,
  },
  [SouthDakota]: {
    id: 41,
    title: 'SD',
    label: 'SD',
    value: 'SD',
    isChecked: false,
  },
  [SouthCarolina]: {
    id: 40,
    title: 'SC',
    label: 'SC',
    value: 'SC',
    isChecked: false,
  },
  [Tennessee]: {
    id: 42,
    title: 'TN',
    label: 'TN',
    value: 'TN',
    isChecked: false,
  },
  [Texas]: {
    id: 43,
    title: 'TX',
    label: 'TX',
    value: 'TX',
    isChecked: false,
  },
  [Utah]: {
    id: 44,
    title: 'UT',
    label: 'UT',
    value: 'UT',
    isChecked: false,
  },
  [Vermont]: {
    id: 45,
    title: 'VT',
    label: 'VT',
    value: 'VT',
    isChecked: false,
  },
  [Virginia]: {
    id: 46,
    title: 'VA',
    label: 'VA',
    value: 'VA',
    isChecked: false,
  },
  [Washington]: {
    id: 47,
    title: 'WA',
    label: 'WA',
    value: 'WA',
    isChecked: false,
  },
  [Wisconsin]: {
    id: 49,
    title: 'WI',
    label: 'WI',
    value: 'WI',
    isChecked: false,
  },
  [WestVirginia]: {
    id: 48,
    title: 'WV',
    label: 'WV',
    value: 'WV',
    isChecked: false,
  },
  [Wyoming]: {
    id: 50,
    title: 'WY',
    label: 'WY',
    value: 'WY',
    isChecked: false,
  },
};
export const stateOptionsArray = Object.values(stateOptions).sort((a, b) => a.label - b.label);
