import Modal from 'react-modal';
import { connect, useSelector } from 'react-redux';
import { getOrganization } from 'core/redux/organizations/selectors';
import {
  createOrganization,
  deactivateOrganization,
  editOrganization,
} from 'core/redux/organizations/actions';
import { addNotification, closeGlobalModal } from 'core/redux/ui/actions';
import { createUser, deactivateUser, editUser } from 'core/redux/users/actions';
import colors from 'shared/constants/colors';
import { modalTypes } from 'shared/constants/modal';
import { SYSTEM_ADMIN, userRoleOptions as userRoles } from 'shared/constants/users';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

import CreateUserForm from './CreateUserForm';
import DeactivateOrganizationContent from './DeactivateOrganizationContent';
import DeactivateUserModal from './DeactivateUserModal';
import UserLimitContent from './UserLimitContent';

Modal.setAppElement('body');
const SoloModal = ({
  modalState: { type, isOpen, vehicleModalVehicleId: vehicleId, parent, formDirty },
  closeGlobalModal,
  user,
  users,
  organizationId,
  createUser,
  editUser,
  deactivateUser,
  deactivateOrganization,
  userRole,
  userModalUsername,
  userModalOrganizationId,
}) => {
  let organization =
    organizationId &&
    useSelector(state => getOrganization(state, state.ui.modal.userModalOrganizationId));

  let organizationToDeactivate =
    organizationId &&
    useSelector(state => getOrganization(state, state.ui.modal.organizationModalOrganizationId));

  const fseUserOptionAvailable =
    organization &&
    organization.organizationName === 'Whelen' &&
    userRole === userRoles[SYSTEM_ADMIN].value;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeGlobalModal}
      shouldCloseOnEsc={formDirty ? false : true}
      shouldCloseOnOverlayClick={formDirty ? false : true}
      parentSelector={() => parent || document.body}
      style={modalStyles[type]}
    >
      <button onClick={closeGlobalModal} style={baseModalStyles.closeButton}>
        <IconSvgComponent svgFileName="x" alt="Close" title="Close" />
      </button>

      {type === modalTypes.editUser ? (
        <CreateUserForm
          editable={true}
          userSelfEdit={false}
          user={(users.length && users.find(user => user.username === userModalUsername)) || user}
          confirmHandler={editUser}
          confirmText={'Update User'}
          headline={'Edit User Profile'}
          cancelHandler={closeGlobalModal}
          cancelText={'Cancel'}
          closeGlobalModal={closeGlobalModal}
          editUser={true}
          organizationId={organizationId}
          fseUserOptionAvailable={fseUserOptionAvailable}
        />
      ) : type === modalTypes.createUser ? (
        <CreateUserForm
          editable={true}
          confirmHandler={createUser}
          headline={'Create User'}
          confirmText={'Create User'}
          cancelHandler={closeGlobalModal}
          cancelText={'Cancel'}
          closeGlobalModal={closeGlobalModal}
          editUser={false}
          organizationId={organizationId}
          fseUserOptionAvailable={fseUserOptionAvailable}
        />
      ) : type === modalTypes.deactivateUser ? (
        <DeactivateUserModal
          userModalOrganizationId={organizationId}
          userModalUsername={userModalUsername}
          confirmHandler={deactivateUser} // user => dispatch(deactivateUser(user)),
          cancelHandler={closeGlobalModal}
          confirmText={'Deactivate User'}
          cancelText={'Cancel'}
          confirmPrompt={`Are you sure you want to deactivate ${userModalUsername}?`}
        />
      ) : type === modalTypes.deactivateOrganization ? (
        <DeactivateOrganizationContent
          organizationId={organizationToDeactivate?.organizationId}
          confirmHandler={(organizationToDeactivate => () => {
            deactivateOrganization(organizationToDeactivate);
          })(organizationToDeactivate)}
          cancelHandler={closeGlobalModal}
          confirmText={'Deactivate Organization'}
          cancelText={'Cancel'}
          confirmPrompt={`Deactivate organization ${organizationToDeactivate &&
            organizationToDeactivate.organizationName}?`}
        />
      ) : type === modalTypes.userLimit ? (
        <UserLimitContent
          dismissHandler={closeGlobalModal}
          dismissButtonText={'Dismiss'}
          notificationHeadline={'User Limit Reached'}
          notificationText={`You have already reached the active user limit for this organization.
                To activate this user, you must first deactivate an active user.`}
        />
      ) : type === modalTypes.userCreationActiveLimit ? (
        <UserLimitContent
          dismissHandler={closeGlobalModal}
          dismissButtonText={'Dismiss'}
          notificationHeadline={'User Limit Reached'}
          notificationText={[
            <div>You have already reached the active user limit for this organization.</div>,
            <div>
              <span>The user </span>
              <span style={{ color: colors.cherry }}>{`${user && user.givenName} ${user &&
                user.familyName}`}</span>
              <span>
                {' '}
                has been created, but their status has been set to Inactive. To activate this user,
                you must first deactivate an active user.
              </span>
            </div>,
          ]}
        />
      ) : null}
    </Modal>
  );
};

export default connect(
  state => ({
    overlayVisible: state.ui.globalOverlayIsVisible,
    loggedIn: state.user.loggedIn,
    modalState: state.ui.modal,
    whitelists: state.whitelists.whitelists,
    configurations: state.configurations.configurations,
    ui: {
      overlayIsVisible: state.ui.overlayIsVisible,
      alertsVisible: state.ui.alertsVisible,
      firmwareUpdateAlerts: state.ui.firmwareUpdateAlerts,
    },
    organizationId:
      state.ui.modal.userModalOrganizationId ||
      state.ui.modal.organizationModalOrganizationId ||
      state.user.filteredOrganizationId ||
      (state.user.user && state.user.user.organizationId),
    organizations: state.organizations.organizations,
    users: state.users.users,
    userModalUsername: state.ui.modal.userModalUsername,
    firmware: state.ui.modal.firmware,
    firmwareUpdateAlertBar: state.ui.firmwareUpdateAlertBar,
    subscriptionExpiryAlertBar: state.ui.subscriptionExpiryAlertBar,
    checkingSession: state.user.checkingSession,
    userRole: state.user.user.userRole,
    user: state.user.user,
    subscriptionExpiryAlertBarDismissed:
      (state.cached.subscriptionExpiryAlertBarDismissed !== null &&
        state.cached.subscriptionExpiryAlertBarDismissed) ||
      (state.ui.subscriptionExpiryAlertBar && state.ui.subscriptionExpiryAlertBar.dismissed),
  }),
  dispatch => ({
    closeGlobalModal: () => dispatch(closeGlobalModal()),
    createUser: props => dispatch(createUser(props)),
    editUser: props => dispatch(editUser(props)),
    deactivateUser: ({ userModalOrganizationId, userModalUsername }) =>
      dispatch(deactivateUser({ userModalOrganizationId, userModalUsername })),
    createOrganization: organization => dispatch(createOrganization(organization)),
    editOrganization: organization => dispatch(editOrganization(organization)),
    deactivateOrganization: organization => dispatch(deactivateOrganization(organization)),
    addNotification: notification => dispatch(addNotification({ notification })),
  }),
)(SoloModal);

const baseModalStyles = {
  overlay: {
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '50px 15px',
    background: 'rgba(33, 31, 80, 0.21)',
    zIndex: 99999,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  content: {
    position: 'relative',
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
    maxWidth: '680px',
    flex: '1 1 680px',
    margin: 'auto',
    padding: '48px 52px 49px 45px',
    background: '#fff',
    boxShadow: '0 41px 51px rgba(0, 0, 0, 0.27)',
    borderRadius: '2px',
    border: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 13,
    right: 21,
    border: 'none',
    backgroundColor: colors.cotton,
    cursor: 'pointer',
  },
};

const modalStyles = {
  [modalTypes.createUser]: baseModalStyles,
  [modalTypes.editUser]: baseModalStyles,
  [modalTypes.deactivateUser]: baseModalStyles,
  [modalTypes.userLimit]: baseModalStyles,
  [modalTypes.userCreationActiveLimit]: baseModalStyles,
  [modalTypes.deactivateOrganization]: baseModalStyles,
  [modalTypes.createOrganization]: baseModalStyles,
  [modalTypes.editOrganization]: baseModalStyles,
};
