import RecipientRowsListContainer from 'app/features/alerts/ui/components/AlertNotificationBuilder/containers/RecipientRowsListContainer';
import AdditionalNotificationHeader from 'app/features/alerts/ui/components/AlertNotificationBuilder/headers/AdditionalNotificationHeader';
import IncludeLocationCheckbox from 'app/features/alerts/ui/components/AlertForms/shared/checkboxes/IncludeLocationCheckbox';
import CooldownPeriodDropdown from 'app/features/alerts/ui/components/AlertForms/shared/dropdowns/CooldownPeriodDropdown';
import DelayDropdown from 'app/features/alerts/ui/components/AlertForms/shared/dropdowns/DelayDropdown';
import {
  DelayCooldownWrapper,
  RecipientHeaderWrapper,
} from 'shared/styles/components/ManageAlerts';

const AdditionalNotificationContainer = ({ notificationIndex }) => {
  return (
    <>
      <RecipientHeaderWrapper>
        <AdditionalNotificationHeader notificationIndex={notificationIndex} />
      </RecipientHeaderWrapper>
      <RecipientRowsListContainer notificationIndex={notificationIndex} />
      <DelayCooldownWrapper>
        <DelayDropdown notificationIndex={notificationIndex} />
        <CooldownPeriodDropdown notificationIndex={notificationIndex} />
      </DelayCooldownWrapper>
      <IncludeLocationCheckbox notificationIndex={notificationIndex} />
    </>
  );
};

export default AdditionalNotificationContainer;
