import {
  FilteredDropdownPopupDiv,
  FilteredDropdownSearchInput,
  FilteredDropdownSearchResultListItem,
  FilteredDropdownSearchResultsList,
} from 'app/shared/styles/FilteredDropdown';
import {
  findStartingIndex,
  highlightSearchText,
} from 'app/shared/utilities/search/highlightSearchText';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const FilteredDropdownPopup = ({
  restrictHeight,
  options,
  searchText,
  hoveredItem,
  setSearchText,
  setHoveredItem,
  setSelectedItem,
}) => {
  return (
    <FilteredDropdownPopupDiv>
      <IconSvgComponent
        svgStyle={{
          height: '22px',
          width: '22px',
          marginRight: '10px',
        }}
        svgFileName={'search'}
        alt="Search"
      />

      <div>
        <FilteredDropdownSearchInput
          onChange={e => setSearchText(e.target.value)}
          autoFocus
          value={searchText}
        />
        <div>
          <FilteredDropdownSearchResultsList restrictHeight={restrictHeight}>
            {options.map((item, index) =>
              searchText === '' || findStartingIndex(searchText, item.label) > -1 ? (
                <FilteredDropdownSearchResultListItem
                  key={`${item.value}${index}`}
                  highlight={index === hoveredItem}
                  showTopBorder={index === 0}
                  onMouseEnter={() => setHoveredItem(index)}
                  onClick={() => setSelectedItem(item)}
                >
                  <TruncatedTextWithWrap title={item.label}>
                    {highlightSearchText(searchText, item.label)}
                  </TruncatedTextWithWrap>
                </FilteredDropdownSearchResultListItem>
              ) : null,
            )}
          </FilteredDropdownSearchResultsList>
        </div>
      </div>
      {/* TODO Create New Item Option */}
    </FilteredDropdownPopupDiv>
  );
};

export default FilteredDropdownPopup;
