import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAlertsForRecipientGroupApi } from 'app/features/alerts/services/api/get/getAlertsForRecipientGroupApi';
import { useDeleteRecipientGroupMutation } from 'app/features/recipientGroups/hooks/mutations/useDeleteRecipientGroupMutation';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import SimpleConfirmationModal from 'shared/ui/modals/SimpleConfirmationModal';

const DeleteRecipientGroupModal = ({ modalData: { show, recipientGroup }, handleCloseModal }) => {
  const deleteRecipientGroupMutation = useDeleteRecipientGroupMutation();
  const [affectedAlerts, setAffectedAlerts] = useState(null);
  const [checkingAffectedAlerts, setCheckingAffectedAlerts] = useState(true);
  const organizationId = useSelector(state => state.user.user.organizationId);
  /* const [alerts, queryKey] = useFetchAlertsForRecipientQuery({
    recipientGroupId,
  }); */
  // TODO: convert to query hook
  const getAffectedAlerts = async recipientGroup => {
    try {
      let result = await getAlertsForRecipientGroupApi({
        recipientGroupId: recipientGroup.recipientGroupId,
        organizationId: organizationId,
      });
      const alertNames = result?.join(', ');
      setAffectedAlerts(alertNames);
      setCheckingAffectedAlerts(false);
    } catch (err) {
      logWcpError(err);
    }
  };
  useEffect(() => {
    // prevent making the call unless the modal is open and there is a recipientGroup loaded
    if (recipientGroup && show) {
      getAffectedAlerts(recipientGroup);
    }
  }, [recipientGroup, show]);

  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={deleteRecipientGroupMutation.isLoading}
        submitHandler={async () => {
          try {
            let result = await deleteRecipientGroupMutation.mutateAsync({
              groupId: recipientGroup.recipientGroupId,
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            handleCloseModal();
          }
        }}
        bodyText={`Do you really want to delete this recipient group: ${recipientGroup.recipientGroupName}?`}
        secondaryBodyText={
          checkingAffectedAlerts
            ? `Checking for affected alerts...`
            : affectedAlerts?.length > 0 &&
              `The following alerts will be affected: ${affectedAlerts}`
        }
        title={`Delete Recipient Group`}
        confirmText="Delete"
        cancelText="Cancel"
      />
    )
  );
};

export default DeleteRecipientGroupModal;
