import { alertMethodConstants } from 'app/features/alerts/data/enums/alertMethodConstants';
import { alertTypeConstants } from 'app/features/alerts/data/enums/alertTypeConstants';
import { alertRecipientMapping } from 'app/features/alerts/services/mapping/definitions/alertRecipientMapping';
import { mapRecipientUserAlertMethod } from 'app/features/alerts/services/mapping/helpers/mapRecipientUserAlertMethod';
import { findRecipientGroupSelector } from 'app/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientGroupSelector';
import { findRecipientRowSelector } from 'app/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientRowSelector';
import { mapToFrontend } from 'app/shared/utilities/mapping/mapper';
import { validateAndExtractPayload } from 'app/shared/utilities/reducerHelpers/validateAndExtractPayload';

// setGroupMembersAction → setGroupMembersReducer
export const setGroupMembersReducer = (state, payload) => {
  const {
    alertNotificationGuid,
    recipientRowGuid,
    fetchedRecipientGroupMembers,
  } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertNotificationGuid', 'recipientRowGuid', 'fetchedRecipientGroupMembers'],
  });

  const recipientRow = findRecipientRowSelector(state, alertNotificationGuid, recipientRowGuid);

  const recipientGroup = recipientRow.recipientGroup;

  if (!recipientGroup) {
    throw new Error(
      `Recipient row does not have a recipient group. Recipient row guid: ${recipientRowGuid}`,
    );
  }

  // set recipientGroupMembers to fetchedRecipientGroupMembers
  recipientGroup.recipientGroupMembers = fetchedRecipientGroupMembers;
  recipientRow.recipientRowAlertMethod = recipientRow.recipientRowAlertMethod || null;

  // console.log('setGroupMembersReducer recipient Row: ', recipientRow);
  return {
    ...state,
  };
};
